import './builder-settings';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import fetch from 'isomorphic-fetch'
import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from '@apollo/client';
import GlobalContext, { GlobalContextType } from './context/globalContext';

const shopifyConfigs: { [key: string]: { shopName: string, storeKey: string, apiVersion: string }} = {};
shopifyConfigs["US"] = {
  shopName: process.env.REACT_APP_US_SHOPIFY_API_DOMAIN || "",
  storeKey: process.env.REACT_APP_US_SHOPIFY_STORE_FRONT_ACCESS_TOKEN || "",
  apiVersion: process.env.REACT_APP_US_SHOPIFY_STORE_FRONT_API_VERSION || "",
}
shopifyConfigs["CA"] = {
  shopName: process.env.REACT_APP_CA_SHOPIFY_API_DOMAIN || "",
  storeKey: process.env.REACT_APP_CA_SHOPIFY_STORE_FRONT_ACCESS_TOKEN || "",
  apiVersion: process.env.REACT_APP_CA_SHOPIFY_STORE_FRONT_API_VERSION || "",
}
shopifyConfigs["UK"] = {
  shopName: process.env.REACT_APP_UK_SHOPIFY_API_DOMAIN || "",
  storeKey: process.env.REACT_APP_UK_SHOPIFY_STORE_FRONT_ACCESS_TOKEN || "",
  apiVersion: process.env.REACT_APP_UK_SHOPIFY_STORE_FRONT_API_VERSION || "",
}
shopifyConfigs["EU"] = {
  shopName: process.env.REACT_APP_EU_SHOPIFY_API_DOMAIN || "",
  storeKey: process.env.REACT_APP_EU_SHOPIFY_STORE_FRONT_ACCESS_TOKEN || "",
  apiVersion: process.env.REACT_APP_EU_SHOPIFY_STORE_FRONT_API_VERSION || "",
}

const urlLocale = new URLSearchParams(window.location.search).get("locale") || process.env.REACT_APP_DEFAULT_LOCALE || "";
const locale = Object.keys(shopifyConfigs).includes(urlLocale) || Object.keys(shopifyConfigs).includes(urlLocale.toUpperCase()) ? urlLocale : "US";

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    uri: `https://${shopifyConfigs[locale].shopName}/api/${shopifyConfigs[locale].apiVersion}/graphql.json`,
    headers: {
      'X-Shopify-Storefront-Access-Token':
      shopifyConfigs[locale].storeKey,
      'Accept' : 'application/graphql'
    },
    fetch,
  }),
});

let currencyCode;
let storeUrl;
switch (locale) {
  case "US":
    currencyCode = "USD";
    storeUrl = process.env.REACT_APP_US_PELA_STORE_URL;
    break;
  case "CA":
    currencyCode = "CAD";
    storeUrl = process.env.REACT_APP_CA_PELA_STORE_URL;
    break;
  case "EU":
    currencyCode = "EUR";
    storeUrl = process.env.REACT_APP_EU_PELA_STORE_URL;
    break;
  case "UK":
    currencyCode = "GBP";
    storeUrl = process.env.REACT_APP_UK_PELA_STORE_URL;
    break;
  default:
    currencyCode = "USD";
    storeUrl = process.env.REACT_APP_PELA_STORE_URL;
    break;
}


const global: GlobalContextType = {
  locale: locale,
  currencyCode: currencyCode,
  storeUrl: storeUrl,
}

ReactDOM.render(
  <GlobalContext.Provider value={global}>
    <ApolloProvider client={client}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ApolloProvider>
  </GlobalContext.Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
