import currency from 'currency.js';

// ISO 4217 https://en.wikipedia.org/wiki/ISO_4217
const getSymbolForCurrencyCode = (currencyCode: string) => {
    switch (currencyCode) {
        case 'USD':
        case 'CAD':
        case 'AUD':
            return '$';
        case 'GBP':
            return '£';
        case 'EUR':
            return '€';
        default:
            console.error(`Unrecognized currency code: ${currencyCode}.  Defaulting to "$".`);
            return '$';
    }
};

const getSuffixForCurrencyCode = (currencyCode: string) => {
    switch (currencyCode) {
        case 'CAD':
            return ' CAD';
        default:
            return '';
    }
};

class CurrencyHelper {
    static formatWithCurrencyCode(currency: currency, currencyCode?: string): string {
        const effectiveCountryCode = currencyCode || 'USD';
        const currencyWithSymbol = currency.format({
            symbol: getSymbolForCurrencyCode(effectiveCountryCode)
        });
        const currencySuffix = getSuffixForCurrencyCode(effectiveCountryCode);
        return `${currencyWithSymbol}${currencySuffix}`;
    }
}

export default CurrencyHelper;
