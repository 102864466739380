import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ButtonLinkProps } from "./buttonLinkProps";
import SmartLink from "../links/smartLink";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    buttonLink: {
        display: "inline-block",
        overflow: 'hidden',
        padding: '8px 16px',
    },
    fullWidthButtonLink: {
        width: '100%',
    },
    buttonSecondary: {
        backgroundColor: 'white',
        border: '1px solid black',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }
    },
}));

export default function ButtonLink(props: ButtonLinkProps) {
    const classes = useStyles();
    const { text, icon, url, fullWidth, primary, onBeforeNavigate } = props;
    const classNameWidth = fullWidth ? `${classes.buttonLink} ${classes.fullWidthButtonLink}` : classes.buttonLink;
    const className = primary ? classNameWidth : `${classNameWidth} ${classes.buttonSecondary}`;
    return (
        <SmartLink url={url} onBeforeNavigate={onBeforeNavigate}>
            {
                primary ?
                <Button variant="contained" color="primary" className={className} endIcon={icon}>{text}</Button>
                :
                <Button variant="contained" className={className} endIcon={icon}>{text}</Button>
            }
        </SmartLink>
    );
};
