import React from "react";
import { SocialIconProps } from "./socialIconProps";
import Facebook from '@material-ui/icons/Facebook';
import Twitter from '@material-ui/icons/Twitter';
import Pinterest from '@material-ui/icons/Pinterest';
import Instagram from '@material-ui/icons/Instagram';
import MailOutline from '@material-ui/icons/MailOutline';
import LinkedIn from '@material-ui/icons/LinkedIn';
import YouTube from '@material-ui/icons/YouTube';

export default function SocialIcon(props: SocialIconProps) {
    switch (props.icon) {
        case 'Facebook': return <Facebook fontSize="large" />;
        case 'Twitter': return <Twitter fontSize="large" />;
        case 'Pinterest': return <Pinterest fontSize="large" />;
        case 'Instagram': return <Instagram fontSize="large" />;
        case 'Mail': return <MailOutline fontSize="large" />;
        case 'LinkedIn': return <LinkedIn fontSize="large" />;
        case 'Youtube': return <YouTube fontSize="large" />;
        default:
            console.error(`Unsupported social icon type: ${props.icon}`);
            return null;
    }
}
