import React from "react";
import { SmartLinkProps } from "./smartLinkProps";

export default function SmartLink(props: SmartLinkProps) {
    const { url, className, children, openInNewTab, onBeforeNavigate } = props;
    const handleClick = async (event: any) => {
        if (onBeforeNavigate) {
            event.preventDefault();
            const shouldNavigate = await onBeforeNavigate();
            if (shouldNavigate) {
                window.location.href = url;
            }
        }
    };

    return (
        <a
            href={url}
            onClick={onBeforeNavigate ? async (event: any) => await handleClick(event) : undefined}
            className={className}
            target={openInNewTab ? '_blank' : '_self'}
        >
            {children}
        </a>
    );
}
