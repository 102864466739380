import React from "react";
import { GuaranteesProps } from "./guaranteesProps";
import Grid from "@material-ui/core/Grid";
import GuaranteeIcon from "./guaranteeIcon";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";

const useStyles = makeStyles(theme => ({
    guaranteesContainer: {
        margin: theme.spacing(4, 0),
    },
    heading: {
        textAlign: 'center',
    },
    gridItem: {
        textAlign: 'center',
        margin: '12px 0',
        padding: '5px',
    },
}));


export default function Guarantees(props: GuaranteesProps) {
    const { guarantees, iconColor, title } = props;
    const classes = useStyles();

    if(!guarantees) {
        return null;
    }

    return (
        <FixedWidthCenteredContent>
            <Grid container className={classes.guaranteesContainer}>
                {
                    title &&
                    <Grid item xs={12}>
                        <Typography variant="overline" className={classes.heading}>{title}</Typography>
                    </Grid>
                }
                {
                    guarantees.map((guarantee, index) => {
                        return (
                            <Grid item className={classes.gridItem} xs={12} md={3} key={index}>
                                <GuaranteeIcon color={iconColor} iconType={guarantee.icon} />
                                <Typography variant="h6">{guarantee.title}</Typography>
                                <Typography variant="body2">{guarantee.description}</Typography>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </FixedWidthCenteredContent>
    );
}
