import { v4 as uuidv4 } from 'uuid';

class GoogleTracker {
    constructor() {
        const hasWindow = typeof window !== 'undefined';
        this.datalayer = hasWindow && window['dataLayer'];
        if (!this.datalayer && hasWindow) { // Only log an error if we're in a browser (not SSR)
            console.log(`No datalayer found.`);
        }
    }

    trackKlaviyoFormSubmit(formId) {
        const uuid = uuidv4();
        try {
            if (!this.datalayer) {
                return;
            }

            this.datalayer.push({
                event: 'klaviyoFormSubmit',
                event_id: uuid,
                formId: formId,
            });

        } catch (error) {
            console.error(`Error GA klaviyoFormSubmit: ${error}`)
        }
    }

    sendEvent(eventCategory, eventAction, eventLabel) {
        try {
            if (!this.datalayer) {
                return;
            }

            this.datalayer.push({
                event : 'GAEvent',
                eventCategory: eventCategory,
                eventAction: eventAction,
                eventLabel: eventLabel,
            });

        } catch (error) {
            console.error(`Error sending GA event: ${error}`);
        }
    }
}

export default GoogleTracker;
