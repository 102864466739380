import React, { useEffect, useState } from "react";
import { ShopifyProduct } from "../../models/shopifyProduct";
import { useQuery, gql } from '@apollo/client';
import { ProductQueryProps } from "./productQueryProps";

const GET_PRODUCT_DETAILS = gql`
    query ProductQuery($handle: String!) {
        productByHandle(handle: $handle) {
            id
            handle
            title
            availableForSale
            descriptionHtml
            productType
            tags
            metafields(identifiers: [{key: "colour", namespace: "demac"}, {key: "handles", namespace: "demac"}]) {
                namespace
                key
                value
            }
            priceRange {
                minVariantPrice {
                    amount
                    currencyCode
                }
            }
            variants(first: 1) {
                edges {
                    node {
                        compareAtPrice {
                            amount
                            currencyCode
                        }
                        id
                    }
                }
            }
            images(first: 2) {
                edges {
                    node {
                        src
                        id
                        altText
                    }
                }
            }
        }
    }
`;

export default function ProductQuery(props: ProductQueryProps) {
    const { loading, data } = useQuery(GET_PRODUCT_DETAILS, {
        variables: { handle: props.handle },
    });

    let effectCanceled = false;
    useEffect(() => {
        if (!effectCanceled && data) {
            if (data.productByHandle === null) {
                if (props.onNotFound) { props.onNotFound(props.handle); }
            } else {
                const product: ShopifyProduct = {
                    ...data?.productByHandle,
                    shopifyId: (data?.productByHandle?.id),
                    metafields: (data?.productByHandle?.metafields as any[]).filter(meta => meta !== null),
                    images: (data?.productByHandle?.images.edges as any[]).map(node => node?.node),
                    variants: (data?.productByHandle?.variants.edges as any[]).map(node => {
                        return {
                            ...node?.node,
                            shopifyId: node?.node?.id,
                            compareAtPrice: node?.node?.compareAtPrice,
                        }
                    }),
                };
                props.onData(product)
            }
            return () => {
                effectCanceled = true
            };
        }
    }, [data]);

    return null;
}
