import React, { useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { gql, useMutation } from '@apollo/client';
import { BuyNowButtonProps } from "./buyNowButtonProps";

const BUY_NOW_BUTTON_HEIGHT = '40px';

const CREATE_CHECKOUT = gql`
    mutation CreateCheckout($items: [CheckoutLineItemInput!]!) {
        checkoutCreate(input: {
            lineItems: $items
        }) {
            checkout {
                id
                webUrl
                lineItems(first: 5) {
                    edges {
                        node {
                            title
                            quantity
                        }
                    }
                }
            }
        }
    }
`;

const useStyles = makeStyles(theme => ({
    outOfStock: {
        color: theme.palette.error.main,
        width: '100%',
        margin: '10px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        height: BUY_NOW_BUTTON_HEIGHT,
        lineHeight: BUY_NOW_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    buyNowButton: {
        width: '100%',
        margin: '10px 0',
        height: BUY_NOW_BUTTON_HEIGHT,
        lineHeight: BUY_NOW_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    showOnHover: {
        [theme.breakpoints.up('sm')]: {
            visibility: 'hidden',
        }
    },
    buttonSecondary: {
        border: '1px solid black',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }
    },
    textSmall: {
        fontSize: '0.8rem',
    }
}));

export default function BuyNowButton(props: BuyNowButtonProps) {
    const classes = useStyles();
    const [createCheckout, { loading, error, data },] = useMutation(CREATE_CHECKOUT);
    const { showOnHover,
            fullWidth,
            primary,
            product,
            textSmall,
            quantity,
            isOutOfStock,
            freeProduct,
            buttonLabel } = props;
    let buttonClass = showOnHover ?  `${classes.buyNowButton} ${classes.showOnHover}` : classes.buyNowButton;
    buttonClass = primary ? buttonClass : `${buttonClass} ${classes.buttonSecondary}`;
    buttonClass = textSmall ? `${buttonClass} ${classes.textSmall}` : buttonClass;

    const buyNowButtonText = buttonLabel ? buttonLabel : 'Buy Now'; // TODO: 118n

    useEffect(() => {
        if (data && data.checkoutCreate && data.checkoutCreate.checkout && data.checkoutCreate.checkout.webUrl) {
            window.location.href = data.checkoutCreate.checkout.webUrl;
        }
    }, [data]);

    return (
        <Button
            className={buttonClass}
            aria-label={buyNowButtonText}
            fullWidth={fullWidth}
            color={primary ? "primary" : "default"}
            variant={primary ? "contained" : "text"}
            onClick={async () => {
                if (freeProduct) {
                    const lineItems = [
                        {
                            variantId: product.variants[0].shopifyId,
                            quantity: 1,
                        },
                        {
                            variantId: freeProduct?.variants[0].shopifyId,
                            quantity: 1,
                        }
                    ];
                    createCheckout({ variables: { items: lineItems }});
                } else {
                    const lineItems = [
                        {
                            variantId: product.variants[0].shopifyId,
                            quantity: 1,
                        }
                    ];
                    createCheckout({ variables: { items: lineItems }});
                }
            }}
        >
            {buyNowButtonText}
        </Button>
    );
};
