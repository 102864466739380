import React, { useState } from "react";
import { ShopifyProduct } from "../../../models/shopifyProduct";
import ProductBuyBlock from "../productBuyBlock";
import ProductGroupQuery from "../productGroupQuery";
import { BuilderProductBlockProps } from "./builderProductBlockProps";

export default function BuilderProductBlock(props: BuilderProductBlockProps) {
    const [products, setProducts] = useState<ShopifyProduct[]>([]);

    const onProductsLoaded = (childProducts: ShopifyProduct[]) => {
        setProducts(childProducts);
    };

    if (!props.handle) {
        return null;
    }

    return (
        <>
            <ProductGroupQuery handle={props.handle} onProductsLoaded={onProductsLoaded}/>
            { products.length > 0 &&
                <ProductBuyBlock
                    products={products}
                    defaultProductHandle={props.handle}
                    disableProductLink
                    image={props.image}
                    useFeatureImage={!!props.image}
                    title={props.title}
                    useProductTitle={!props.title}
                    descriptionHtml={props.descriptionHtml}
                    mobileHtml={props.mobileHtml}
                    urlParameterString={props.urlParameterString}
                    landingPageOnATC={props.landingPageOnATC}
                />
            }
        </>
    )
}
