import React from "react";
import { ImageGridProps } from "./imageGridProps";
import Grid from "@material-ui/core/Grid";
import HtmlBlock from "../html/htmlBlock";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ButtonLink from '../buttonLink/buttonLink';
import { Typography } from "@material-ui/core";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";

const useStyles = makeStyles(theme => ({
    title: {
        textAlign: 'center',
    },
    textCenter: {
        textAlign: 'center',
    },
    description: {
        textAlign: 'center',
        maxWidth: '900px',
        margin: 'auto',
        '& a': {
            color: '#000',
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
        },
        '& a:hover': {
            color: theme.palette.primary.main,
        },
    },
    itemPreTitle: {
        margin: '0',
        color: theme.palette.primary.main,
    },
    container: {
        margin: theme.spacing(4, 'auto'),
        '&>div': {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
        },
    },
    itemContainer: {
      [theme.breakpoints.only('sm')]: {
        marginLeft: '25%',
      },
    },
    itemTitle: {
        margin: '0',
    },
    grid2: {
        textAlign: 'right',
        paddingRight: '15px',
    },
    grid2Full: {
        textAlign: 'center',
        marginTop: '15px',
    },
    itemDescription: {
        textAlign: 'center',
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    button: {
        textAlign: 'center',
        marginTop: '10px',
    },
    builderImage: {
        width: '100%',
    }
}));

export default function ImageGrid(props: ImageGridProps) {
    const classes = useStyles();
    const { imageListItems } = props;
    if (!imageListItems || imageListItems.length === 0) {
        return null;
    }
    const { pretitle, title, description, titleBesideIcon, linkText, linkUrl, numberOfCol } = props;
    const getColSize = () => {
        if (numberOfCol == 2) {
          return 6;
        }

        if (numberOfCol == 3) {
          return 4;
        }

        if (numberOfCol == 4) {
          return 3;
        }

        return 4;
      }
    return (
        <FixedWidthCenteredContent>
            <Grid container className={classes.container}>
                <Grid item xs={12} className={classes.textCenter}>
                    {
                        pretitle &&
                        <Typography variant="overline">{pretitle}</Typography>
                    }
                    {
                        title &&
                        <Typography variant="h3" className={classes.title}>{title}</Typography>
                    }
                    {
                        description &&
                        <HtmlBlock className={classes.description} html={description} />
                    }
                </Grid>
                {
                    imageListItems.map((item, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={getColSize()}
                                container
                                direction={titleBesideIcon ? "row-reverse" : undefined}
                                alignItems={titleBesideIcon ? "center" : "baseline"}
                                alignContent="flex-start"
                                className={classes.itemContainer}
                                key={index}
                            >
                                <Grid item xs={titleBesideIcon ? 6 : 12}>
                                    <div>
                                        {
                                            item.linkUrl ?
                                            <a href={item.linkUrl} target="_blank" rel="noopener">
                                                <img className={classes.builderImage} src={item.image} />
                                            </a>
                                            :
                                            <img className={classes.builderImage} src={item.image} />
                                        }
                                    </div>
                                </Grid>
                                {
                                    item.pretitle || item.title ?
                                    <Grid item xs={titleBesideIcon ? 6 : 12} lg={titleBesideIcon ? 5 : 12} className={titleBesideIcon ? classes.grid2 : classes.grid2Full}>
                                        {
                                            item.pretitle &&
                                            <Typography variant="h2" className={classes.itemPreTitle}>{item.pretitle}</Typography>
                                        }
                                        {
                                            item.title &&
                                            <Typography variant="h5" className={classes.itemTitle}>{item.title}</Typography>
                                        }
                                    </Grid>
                                    :
                                    <></>
                                }
                                {
                                    titleBesideIcon &&
                                    <Grid item lg={1}></Grid>
                                }
                                {
                                    item.descriptionHtml &&
                                    <Grid item xs={12} className={titleBesideIcon ? "" : classes.itemDescription}>
                                        <HtmlBlock html={item.descriptionHtml} />
                                    </Grid>
                                }
                                {
                                    item.linkText &&
                                    <Grid item xs={12} className={classes.button}><ButtonLink text={item.linkText} url={item.linkUrl} /></Grid>
                                }
                            </Grid>
                        );
                    })
                }
                {
                    linkText &&
                    <Grid item xs={12} className={classes.button}><ButtonLink text={linkText} url={linkUrl} /></Grid>
                }
            </Grid>
       </FixedWidthCenteredContent>
    );
}
