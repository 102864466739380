import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ProductPriceProps } from "./productPriceProps";
import { Typography } from "@material-ui/core";
import CurrencyHelper from "../../../utilities/currencyHelper";
import GlobalContext from "../../../context/globalContext";

const useStyles = makeStyles(theme => ({
    smallCompareAtPriceStrikethrough: {
        color: theme.palette.grey[300],
        fontSize: '0.875rem',
        margin: '0 10px 0 10px',
        textDecoration: 'line-through',
        display: 'inline-block',
    },
    smallPrice: {
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        display: 'inline-block',
    },
    smallPriceStrikethrough: {
        color: theme.palette.grey[300],
        fontSize: '0.875rem',
        display: 'inline-block',
        textDecoration: 'line-through',
    },
    smallMemberPrice: {
        color: theme.palette.text.secondary,
        fontSize: '0.875rem',
        display: 'block',
    },
    smallPercentOff: {
        color: theme.palette.primary.main,
        fontSize: '0.875rem',
        display: 'inline-block',
    },
    smallBundleDiscount: {
        fontSize: '0.875rem',
        display: 'block',
    },
    smallDiscountTierIcon: {
        backgroundColor: theme.palette.primary.main,
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        backgroundPosition: 'center center',
        display: 'inline-block',
        margin: '0 0 0 5px',
        verticalAlign: 'top',
    },
    largeCompareAtPriceStrikethough: (props: ProductPriceProps) => ({
        color: theme.palette.grey[300],
        fontSize: '1.375rem',
        fontWeight: theme.typography.fontWeightRegular,
        textDecoration: 'line-through',
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
        margin: props.displayMode === 'large-inline' ? '0 16px' : '0',
    }),
    largePrice: (props: ProductPriceProps) => ({
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
        fontSize: '1.375rem',
        fontWeight: theme.typography.fontWeightRegular,
    }),
    largePriceStrikethrough: (props: ProductPriceProps) => ({
        color: theme.palette.grey[300],
        fontSize: '1.375rem',
        fontWeight: theme.typography.fontWeightRegular,
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
        textDecoration: 'line-through',
    }),
    largeMemberPrice: (props: ProductPriceProps) => ({
        fontSize: '1.375rem',
        fontWeight: theme.typography.fontWeightRegular,
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
    }),
    largePercentOff: (props: ProductPriceProps) => ({
        color: theme.palette.primary.main,
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
        fontSize: '1.375rem',
        fontWeight: theme.typography.fontWeightRegular,
    }),
    largeBundleDiscount: (props: ProductPriceProps) => ({
        display: props.displayMode === 'large-inline' ? 'inline-block' : 'block',
        fontSize: '1.0rem',
        fontWeight: theme.typography.fontWeightRegular,
    }),
    largeBundleDescription :{
        display: "inline-block",
    },
    largeDiscountTierIcon: {
        backgroundColor: theme.palette.primary.main,
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        backgroundPosition: 'center center',
        display: 'inline-block',
        margin: '0 0 0 5px',
        verticalAlign: 'top',
    },
    priceRed: {
        color: theme.palette.error.main,
    },
}));

export default function ProductPrice(props: ProductPriceProps) {
    const { productPriceInfo, displayMode, productHandle, discountPercent, showAsFree } = props;
    const classes = useStyles(props);
    const isSmall = displayMode === 'small';
    const compareAtPriceStrikethroughClassName = isSmall ? classes.smallCompareAtPriceStrikethrough : classes.largeCompareAtPriceStrikethough;
    const priceClassName = isSmall ? classes.smallPrice : classes.largePrice;

    return (
        <GlobalContext.Consumer>
            {context => {
                if (!context || !context.currencyCode) {
                    return null;
                }
                if (showAsFree) {
                    return (
                        <span>
                            <span className={priceClassName}>{"Free"}</span>
                            <span className={compareAtPriceStrikethroughClassName}>{CurrencyHelper.formatWithCurrencyCode(productPriceInfo.price, context.currencyCode)}</span>
                        </span>
                    );
                }

                if (discountPercent) {
                    return (
                        <span>
                            <span className={priceClassName}>{CurrencyHelper.formatWithCurrencyCode(productPriceInfo.price.multiply(1 - (discountPercent / 100)), context.currencyCode)}</span>
                            <span className={compareAtPriceStrikethroughClassName}>{productPriceInfo.compareAtPrice ? CurrencyHelper.formatWithCurrencyCode(productPriceInfo.compareAtPrice, context.currencyCode) : CurrencyHelper.formatWithCurrencyCode(productPriceInfo.price, context.currencyCode)}</span>
                        </span>
                    );
                }

                if (productPriceInfo.isDiscounted) {
                    return (
                        <span>
                            <span className={priceClassName}>{CurrencyHelper.formatWithCurrencyCode(productPriceInfo.price, context.currencyCode)}</span>
                            <span className={compareAtPriceStrikethroughClassName}>{productPriceInfo.compareAtPrice ? CurrencyHelper.formatWithCurrencyCode(productPriceInfo.compareAtPrice, context.currencyCode) : null}</span>
                        </span>
                    );
                }
                return (
                    <span>
                        <span className={priceClassName}>{CurrencyHelper.formatWithCurrencyCode(productPriceInfo.price, context.currencyCode)}</span>
                    </span>
                );
            }}
        </GlobalContext.Consumer>
    )
}
