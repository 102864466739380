import React from "react";
import { BannerImageOverlayProps } from "./bannerImageOverlayProps";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Button, fade } from "@material-ui/core";
import HtmlBlock from "../html/htmlBlock";

const useStyles = makeStyles(theme => ({
    container: {
        height: '100%',
        padding: '15px',
    },
    containerInner: {
        margin: 'auto',
        textAlign: 'center',
        padding: '0 20px',
        [theme.breakpoints.only('xs')]: {
            padding: '0',
        },
    },
    icon: {
        maxWidth: '200px',
        margin: '10px auto',
        [theme.breakpoints.only('xs')]: {
            maxWidth: '100px',
        },
    },
    ctaButton: (props: BannerImageOverlayProps) => ({
        color: props.ctaButtonTextColor ? props.ctaButtonTextColor : 'white',
        background: props.ctaButtonBackgroundColor ? props.ctaButtonBackgroundColor : theme.palette.primary.main,
        padding: '12px 24px',
        margin: theme.spacing(3, 0),
        '&:hover': {
            background: fade(props.ctaButtonBackgroundColor ? props.ctaButtonBackgroundColor : theme.palette.primary.main, 0.5)
        }
    }),
}));

export default function BannerImageOverlay(props: BannerImageOverlayProps) {
    const classes = useStyles(props);
    const { html, ctaButtonText, ctaButtonLink, postButtonHtml } = props;

    return (
        <Grid container alignItems="center" className={classes.container}>
            <Grid item className={classes.containerInner}>
                <HtmlBlock html={html}/>
                { ctaButtonLink &&
                    <Button className={classes.ctaButton} onClick={async () => {window.location.href = ctaButtonLink}}>{ctaButtonText}</Button>
                }
                <HtmlBlock html={postButtonHtml}/>
            </Grid>
        </Grid>
    );
};
