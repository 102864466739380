import React from "react";
import { ScoreDisplayProps } from "./scoreDisplayProps";
import { Star } from "./star";

export function ScoreDisplay(props: ScoreDisplayProps) {
    const { score, outOf, size, color } = props;
    const boxedScore = Math.max(Math.min(Math.ceil(score), 5), 0);
    return (
        <>
            {[...Array(boxedScore)].map((s,i) => <Star key={i} size={size || 'default'} variant="filled" color={color}/>)}
            {[...Array(outOf-boxedScore)].map((s,i) => <Star key={i} size={size || 'default'} variant="outlined" color={color}/>)}
        </>
    );
}
