import React, { useState } from 'react';
import builder, { BuilderComponent } from '@builder.io/react';
import NotFoundPage from './404';
import RootPage from './root';
import { CircularProgress } from '@material-ui/core';
import "../components/imageCarousel/imageCarousel.builder";
import "../components/product/builderProductBlock/builderProductBlock.builder";
import "../components/product/builderProductCollection/builderProductCollection.builder";
import "../components/builderHyperlink/builderHyperlink.builder";
import "../components/referralCollection/builderReferralCollection.builder";
import "../components/showCase/twoColumnShowCase.builder";
import "../components/imageGrid/imageGrid.builder";
import "../components/hero/hero.builder";
import "../components/imageBand/imageBand.builder";
import "../components/video/videoSection.builder";
import "../components/reviews/reviewsSection.builder";
import "../components/buttonAndText/builderButtonAndText.builder";
import "../components/klaviyo/klaviyoForm.builder";
import "../components/guarantees/guarantees.builder";
import "../components/image/image.builder";
import "../components/limitedInventoryCollection/builderLimitedInventoryCollection.builder";
import "../components/script/script.builder";

export default function LandingPage() {
    const [notFound, setNotFound] = useState(false);
    const [disableHeader, setDisableHeader] = useState(true);
    const [disableFooter, setDisableFooter] = useState(true);
    const [backgroundColour, setBackgroundColour] = useState(undefined);
    const [headerColour, setHeaderColour] = useState(undefined);
    const [ctaText, setCTAText] = useState(undefined);
    const [ctaUrl, setCTAUrl] = useState(undefined);
    const [ctaColour, setCTAColour] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [description, setDescription] = useState(undefined);
    const [caption, setCaption] = useState(undefined);

    return (
        <>
            { !notFound ?
                <RootPage
                    seoTitle={title}
                    seoDescription={description}
                    disableHeader={disableHeader}
                    disableFooter={disableFooter}
                    backgroundColour={backgroundColour}
                    header="test"
                    headerColour={headerColour}
                    caption={caption}
                    ctaText={ctaText}
                    ctaUrl={ctaUrl}
                    ctaColour={ctaColour}
                >
                    <BuilderComponent
                        model="page"
                        contentLoaded={(data, content) => {
                            if (data) {
                                setDisableFooter(data.disableFooter);
                                setDisableHeader(data.disableHeader);
                                setBackgroundColour(data.backgroundColour);
                                setHeaderColour(data.headerColour);
                                setCTAText(data.ctaText);
                                setCTAUrl(data.ctaUrl);
                                setCTAColour(data.ctaColour);
                                setTitle(data.title);
                                setDescription(data.description);
                                setCaption(data.caption)
                            }
                            if (!content) {
                                setNotFound(true);
                            }}
                        }
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', padding: 100 }}>
                            <CircularProgress color="inherit" disableShrink />
                        </div>
                    </BuilderComponent>
                </RootPage> :
                <NotFoundPage />
            }
        </>
    )
}
