import { Builder, builder } from '@builder.io/react';
import Hero from './hero';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(Hero, {
    name: "Hero",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "layout",
            type: "text",
            defaultValue: "Two Column",
            enum: ["One Column", "Two Column"],
        },
        {
            name: "html",
            type: "richText",
        },
        {
            name: "ctaButtonText",
            type: "string",
        },
        {
            name: "ctaButtonLink",
            type: "string",
        },
        {
            name: "ctaButtonSlug",
            type: "string",
        },
        {
            name: "backgroundColor",
            type: "color",
            defaultValue: "#F4F4F2",
        },
        {
            name: "buttonBackgroundColor",
            type: "color",
            defaultValue: "#8ebd04",
        },
        {
            name: "buttonTextBlack",
            type: "boolean",
            helperText: "Button text color: defaults to white if off, black if on",
        },
        {
            name: "postButtonHtml",
            type: "richText",
        },
        {
            name: "image",
            type: "file",
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
            required: true,
        }
    ]
})
