import React from "react";
import { StarProps } from "./starProps";
import StarIcon from '@material-ui/icons/Star';
import StartBorderIcon from '@material-ui/icons/StarBorder';

const STAR_COLOR = '#D6B690';

export function Star(props: StarProps) {
    if (props.variant === 'filled') {
        return <StarIcon fontSize={props.size} htmlColor={props.color ? props.color : STAR_COLOR} />
    }
    return <StartBorderIcon fontSize={props.size} htmlColor={props.color ? props.color : STAR_COLOR} />
}
