import React, { useState } from "react";
import { ShopifyProduct } from "../../../models/shopifyProduct";
import ProductGroupQuery from "../productGroupQuery";
import ProductSingleQuery from "../productSingleQuery";
import ProductThumbnail from "../productThumbnail";
import { ProductCollectionItemProps } from "./productCollectionItemProps";


export default function ProductCollectionItem(props: ProductCollectionItemProps) {
    const [products, setProducts] = useState<ShopifyProduct[]>([]);

    const onProductsLoaded = (childProducts: ShopifyProduct[]) => {
        setProducts(childProducts);
    };

    if (!props.handle) {
        return null;
    }

    if (props.excludeVariants) {
        return (
            <>
                <ProductSingleQuery handle={props.handle} onProductsLoaded={onProductsLoaded}/>
                { products.length > 0 &&
                    <ProductThumbnail
                        products={products}
                        alwaysShowButton={props.alwaysShowButton}
                        useViewProductButton={props.useViewProductButton}
                        title={props.title}
                        description={props.description}
                        urlParameterString={props.urlParameterString}
                        showAsSoldOut={props.showAsSoldOut}
                        showAsLowStock={props.showAsLowStock}
                        index={props.index}
                    />
                }
            </>
        )
    } else {
        return (
            <>
                <ProductGroupQuery handle={props.handle} onProductsLoaded={onProductsLoaded}/>
                { products.length > 0 &&
                    <ProductThumbnail
                        products={products}
                        alwaysShowButton={props.alwaysShowButton}
                        useViewProductButton={props.useViewProductButton}
                        title={props.title}
                        description={props.description}
                        urlParameterString={props.urlParameterString}
                        showAsSoldOut={props.showAsSoldOut}
                        showAsLowStock={props.showAsLowStock}
                        index={props.index}
                    />
                }
            </>
        )
    }
}
