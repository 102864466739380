import React from "react";
import Script from "../script/script";
import { KlaviyoFormProps } from './klaviyoFormProps';
import { builder } from '@builder.io/react';
import GoogleTracker from '../../utilities/googleTracker.js';

builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");


export default function KlaviyoForm(props: KlaviyoFormProps) {
    const { form, whichKlaviyo, trackingEnabled } = props;
    const googleTracker = new GoogleTracker();

    let klaviyoId="rQmDgT";

    if (whichKlaviyo === "ca") {
        klaviyoId = "YwdpTB";
    } else if (whichKlaviyo === "uk") {
        klaviyoId = "SLN8wB";
    } else if (whichKlaviyo === "eu") {
        klaviyoId = "WkGTrM";
    } else if (whichKlaviyo === "lomi") {
        klaviyoId = "SZEvVS";
    }

    const leadTrackEvent = (e: any) => {
        if (e.detail.type == 'submit') {
            googleTracker.trackKlaviyoFormSubmit(e.detail.formId);
            builder.trackConversion();
        }
    };

    React.useEffect(() => {
        if (trackingEnabled) {
            window.addEventListener('klaviyoForms', leadTrackEvent);

            // cleanup this component
            return () => {
                window.removeEventListener('klaviyoForms', leadTrackEvent);
            };
        }
    }, []);

    return (
        <Script headScript={`https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${klaviyoId}`} isSource isBodyScript={false} idDiv={form}/>
    );
};
