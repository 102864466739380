import React, { useEffect, useState } from "react";
import { ShopifyProduct } from "../../models/shopifyProduct";
import { useQuery, gql } from '@apollo/client';
import { ReferralCollectionProps } from "./referralCollectionProps";
import { Container, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select, Typography } from "@material-ui/core";
import DescriptionHtml from "../descriptions/descriptionHtml";
import ProductThumbnail from "../product/productThumbnail";

const useStyles = makeStyles(theme => ({
    productThumbnailContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px',
            boxShadow: '0px 0px 8px 1px rgba(230,230,230,1)',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    label: {
        textTransform: 'uppercase',
        display: 'block',
    },
    deviceSelectorContainer: {
        margin: `${theme.spacing(2)}px 0px`,
    },
    deviceSelector: {
        width: '100%',
    },
    title: {
        margin: '0 auto 1.375rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
        },
    },
    description: {
        margin: '0 auto 2rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '50%',
        },
        [theme.breakpoints.only('sm')]: {
            maxWidth: '90%',
        },
    },
    textAlignCenter: {
        textAlign: "center",
    },
}));

const GET_COLLECTION_DETAILS = gql`
    query ReferralCollectionQuery($handle: String!) {
        collectionByHandle(handle: $handle) {
            handle
            id
            title
            products(first: 100) {
                edges {
                    node {
                        id
                        handle
                        title
                        availableForSale
                        descriptionHtml
                        productType
                        tags
                        metafields(identifiers: [{key: "colour", namespace: "demac"}, {key: "handles", namespace: "demac"}]) {
                            namespace
                            key
                            value
                        }
                        priceRange {
                            minVariantPrice {
                                amount
                                currencyCode
                            }
                        }
                        variants(first: 1) {
                            edges {
                                node {
                                    compareAtPrice {
                                        amount
                                        currencyCode
                                    }
                                    id
                                }
                            }
                        }
                        images(first: 2) {
                            edges {
                                node {
                                    src
                                    id
                                    altText
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const dynamicSort = (property: string) => {
    var sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a: any, b: any) {
        if(sortOrder == -1){
            return b[property].localeCompare(a[property]);
        }else{
            return a[property].localeCompare(b[property]);
        }
    }
}

export default function ReferralCollection(props: ReferralCollectionProps) {
    const classes = useStyles();
    const { handle, freeProduct, title, descriptionHtml, claimButtonLabel, alwaysShowButton } = props;
    const { loading, data } = useQuery(GET_COLLECTION_DETAILS, {
        variables: { handle: handle },
    });
    const [products, setProducts] = useState<ShopifyProduct[]>([]);

    useEffect(() => {
        if (data) {
            const returnProducts = (data.collectionByHandle?.products?.edges as any[]).map((edge) => edge.node);
            const finalProducts = returnProducts.map((item) => {
                return {
                    ...item,
                    shopifyId: (item.id),
                    metafields: (item.metafields as any[]).filter(meta => meta !== null),
                    images: (item.images.edges as any[]).map(node => node?.node),
                    variants: (item.variants.edges as any[]).map(node => {
                        return {
                            ...node?.node,
                            shopifyId: node?.node?.id,
                            compareAtPrice: node?.node?.compareAtPrice,
                        }
                    }),
                } as ShopifyProduct;
            });

            finalProducts.sort(dynamicSort("productType"));
            setProducts(finalProducts);
        }
    }, [data]);

    const [state, setState] = React.useState({
        selectedProductTypeIndex: 0,
    });

    const setSelectedProductTypeIndex = (selectedProductTypeIndex: number) => {
        setState((prevState) => {
            return { ...prevState, selectedProductTypeIndex };
        });
    };

    const handleChange = (event: any) => {
        setSelectedProductTypeIndex(event.target.value);
    };

    if (loading) {
        return null;
    } else if (products && products.length) {
        const productsByType = products.reduce((map: { [key: string]: ShopifyProduct[] }, product) => {
            const group = (map[product.productType] || []);
            group.push(product);
            map[product.productType] = group;
            return map;
        }, {});

        const productTypes = Object.keys(productsByType);

        const selectedProductType = productTypes[state.selectedProductTypeIndex];

        return (
            <Container fixed>
                <div>
                    <Grid className={classes.deviceSelectorContainer} container justify={"center"}>
                        { title &&
                            <Grid container item xs={12} className={classes.textAlignCenter} justify={"center"}>
                                <Grid item xs={12} md={10} lg={8}>
                                    <Typography variant={"h1"}>{title}</Typography>
                                </Grid>
                            </Grid>
                        }
                        { descriptionHtml &&
                            <Grid container item xs={12} className={classes.textAlignCenter} justify={"center"}>
                                <Grid item xs={12} md={10} lg={8}>
                                    <DescriptionHtml html={descriptionHtml} />
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={10} sm={8} md={6} lg={4}>
                            <Typography variant="caption" className={classes.label}>{"Phone Model"}</Typography>
                            <FormControl className={classes.deviceSelector} size="small">
                                <Select
                                    variant="outlined"
                                    value={state.selectedProductTypeIndex}
                                    onChange={handleChange}
                                >
                                    {
                                        productTypes.map((type: any, index) => {
                                            return (
                                                <MenuItem
                                                    key={index}
                                                    value={index}
                                                    selected={index === state.selectedProductTypeIndex}
                                                >
                                                    {type}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} alignItems="stretch">
                        {
                            productsByType[selectedProductType].map((product: ShopifyProduct, index) => {
                                return (
                                    <Grid item key={`${product.handle}-${index}`} xs={12} sm={6} md={4} lg={3}>
                                        <div className={classes.productThumbnailContainer}>
                                            <ProductThumbnail
                                                products={[product]}
                                                alwaysShowButton={alwaysShowButton}
                                                useBuyNowButton
                                                freeProduct={freeProduct}
                                                buyButtonLabel={claimButtonLabel}
                                                showAsSoldOut={false}
                                                showAsLowStock={false}
                                            />
                                        </div>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                </div>
            </Container>
        );
    } else {
        return null;
    }
}
