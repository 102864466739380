import { Builder, builder } from '@builder.io/react';
import KlaviyoForm from './klaviyoForm'
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(KlaviyoForm, {
    name: "Klaviyo Form",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "whichKlaviyo",
            type: "text",
            defaultValue: "com",
            enum: ["com", "ca", "uk", "eu", "lomi"],
        },
        {
            name: "form",
            type: "string",
        },
        {
            name: "trackingEnabled",
            type: "boolean",
            defaultValue: false,
        }
    ]
})
