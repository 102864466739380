import { Builder, builder } from '@builder.io/react';
import Guarantees from './guarantees';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(Guarantees, {
    name: "Guarantees",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "title",
            type: "string",
        },
        {
            name: "iconColor",
            type: "color",
        },
        {
            name: "guarantees",
            type: "list",
            required: true,
            subFields: [
                {
                    name: "title",
                    type: "string",
                },
                {
                    name: "description",
                    type: "string"
                },
                {
                    name: "icon",
                    type: "text",
                    required: true,
                    enum: ['Recycle Loop', 'Leaf', 'Mobile Phone', 'Happy Face', 'Security Shield', 'Globe', 'Ocean Waves'],
                },
            ]
        },
    ]
})
