import { Builder, builder } from '@builder.io/react';
import BuilderHyperlink from './builderHyperlink';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(BuilderHyperlink, {
    name: "Anchor Location",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "linkId",
            type: "string",
            required: true,
        }
    ]
});
