import React from "react";
import { ImageProps } from "./imageProps";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    imageContainer: {
        textAlign: "center",
        marginBottom: theme.spacing(4),
    },
    fullWidth: {
        width: "100%",
    },
    notFullWidth: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    verticalMargin: {
        marginTop: theme.spacing(4),
    },
    image: {
        maxWidth: "100%",
    },
    imageFullWidth: {
        width: "100%",
    },
}));
  
export default function Image(props: ImageProps) {
    const { image, fullWidth, noVerticalMargin } = props;

    const classes = useStyles();
    const verticalMarginClass = noVerticalMargin ? '' :  ` ${classes.verticalMargin}`;
    const widthClass = fullWidth ? ` ${classes.fullWidth}` : ` ${classes.notFullWidth}`;
    const imageContainerClass = `${verticalMarginClass} ${widthClass} ${classes.imageContainer}`;
    return (
        <div className={imageContainerClass}><img className={fullWidth ? classes.imageFullWidth :classes.image} src={image} /></div>
    );
};
