import React from "react";
import pelaLogo from "../static/pela-logo-grey.png";
import climateNeutralLogo from "../static/climate-neutral.png";
import onePercentLogo from "../static/one-percent-for-the-planet.png";
import bCorpLogo from "../static/b-corp.png";
import masterCardLogo from "../static/master-card-payment.svg";
import visaLogo from "../static/visa-payment.svg";
import amexLogo from "../static/amex-payment.svg";
import discoverLogo from "../static/discover-payment.svg";
import shopifyPaymentLogo from "../static/shopify-payment.svg";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import SocialIcon from "./icons/socialIcon";
import { SocialIconType } from "./icons/socialIconProps";
import EmailSignup from "./klaviyo/emailSignup";

const useStyles = makeStyles(theme => ({
    footer: {
        backgroundColor: theme.palette.grey[900],
        color: "#fff",
        minHeight: "300px",
        paddingTop: "30px",
        paddingBottom: "30px",
        marginTop: '30px',
        [theme.breakpoints.only('xs')]: {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: "50px",
            paddingRight: "50px",
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: "100px",
            paddingRight: "100px",
        }
    },
    fullWidth: {
        width: '100%',
    },
    logoContainer: {
        textAlign: "center",
        marginBottom: theme.spacing(2),
        marginTop: 0,
        paddingTop: "16px",
    },
    textContainer: {
        textAlign: "center",
        margin: "0 auto",
        paddingBottom: "32px",
    },
    image: {
        maxWidth: "120px",
        width: "120px",
        height: "90",
    },
    socialLinks: {
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
            textAlign: 'center',
        },
        '& a' : {
            color: '#fff',
            display: "inline-block",
            marginLeft: "10px",
            [theme.breakpoints.down('sm')]: {
                margin: '0 5px',
            },
        },
    },
    navLinkGrid: {
        textAlign: "center",
    },
    navLink: {
        color: "#fff",
        fontWeight: "bold",
        fontSize: "1rem",
        textDecoration: "none",
        display: 'inline-block',
        textAlign: 'center',
        padding: "5px 20px 5px 0",
        [theme.breakpoints.only('md')]: {
            maxWidth: '70px',
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px',
            padding: '5px 10px',
        },
    },
    emailSignupContainer: {
        [theme.breakpoints.only('sm')]: {
            marginTop: '50px',
        },
        [theme.breakpoints.only('xs')]: {
            marginTop: '30px',
        },
    },
    emailSignupHeading: {
        color: 'white',
        marginBottom: '10px',
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
        },
    },
    emailSignupText: {
        color: 'white',
        marginBottom: '10px',
        [theme.breakpoints.only('xs')]: {
            textAlign: 'center',
        },
    },
    corpLinks: {
        textAlign: 'right',
        marginTop : '40px',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    corpLink: {
        margin: '20px 10px',
        display: 'inline-block',
    },
    copyright: {
        color: theme.palette.grey[300],
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
            paddingTop: '20px',
        },
    },
    policyLinks: {
        [theme.breakpoints.up('xs')]: {
            textAlign: 'center',
        },
        [theme.breakpoints.up('md')]: {
            textAlign: 'right',
        },
        '& a' : {
            display: "inline-block",
            marginLeft: "30px",
            textDecoration: 'none',
            [theme.breakpoints.down('sm')]: {
                margin: '0 5px',
            },
            '& p': {
                color: theme.palette.grey[300],
            }
        },
    },
    paymentImages: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
        },
    },
    paymentImageWrap: {
        margin: '10px 3px',
        width: '40px',
        maxWidth: '40px',
        display: 'inline-block',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
}));

interface FooterNavigationLink {
    linkHover: string;
    linkText: string;
    linkUrl: string;
}

interface FooterPartnerLink {
    linkHover: string;
    linkUrl: string;
    imageSrc: string;
}

interface FooterSocialLink {
    icon: SocialIconType;
    linkHover: string;
    linkUrl: string;
}

interface FooterPolicyLink{
    linkText: string;
    linkUrl: string;
}

interface PaymentType {
    image: {
        url: string; // SVG image url
        alt: string;
    }
}

const paymentTypes: PaymentType[] = [
    {
        image: {
            url: masterCardLogo,
            alt: "Mastercard",
        }
    },
    {
        image: {
            url: visaLogo,
            alt: "Visa",
        }
    },
    {
        image: {
            url: amexLogo,
            alt: "Amex",
        }
    },
    {
        image: {
            url: discoverLogo,
            alt: "Discover",
        }
    },
    {
        image: {
            url: shopifyPaymentLogo,
            alt: "Diners Club",
        }
    },
];

const footerPolicyLinks: FooterPolicyLink[] = [
    {
        linkText: "Terms & Conditions",
        linkUrl: `${process.env.REACT_APP_PELA_STORE_URL}/policies/terms-of-service`,
    },
    {
        linkText: "Privacy Policy",
        linkUrl: `${process.env.REACT_APP_PELA_STORE_URL}/policies/privacy-policy`,
    },
];

const footerPartnerLinks: FooterPartnerLink[] = [
    {
        linkHover: "Climate Neutral Certified",
        linkUrl: "https://www.climateneutral.org/brand/pela",
        imageSrc: climateNeutralLogo,
    },
    {
        linkHover: "Certified B Corporation",
        linkUrl: "https://bcorporation.net/directory/pela",
        imageSrc: bCorpLogo,
    },
    {
        linkHover: "1% for the Planet",
        linkUrl: "https://directories.onepercentfortheplanet.org/business-members/open-mind-developments-corporation",
        imageSrc: onePercentLogo,
    },
];

const footerNavigationLinks: FooterNavigationLink[] = [
    {
        linkHover: "Contact Us",
        linkText: "Contact Us",
        linkUrl: `${process.env.REACT_APP_PELA_STORE_URL}/pages/contact-us`,
    },
    {
        linkHover: "Shipping",
        linkText: "Shipping",
        linkUrl: "https://help.pelacase.com/article/ehnsfoypiz-how-long-will-it-take-to-receive-my-order",
    },
    {
        linkHover: "Returns",
        linkText: "Returns",
        linkUrl: `${process.env.REACT_APP_PELA_STORE_URL}/returns`,
    },
    {
        linkHover: "Frequently Asked Questions",
        linkText: "FAQ",
        linkUrl: "https://help.pelacase.com",
    },
    {
        linkHover: "Wholesale Inquiries",
        linkText: "Wholesale Inquiries",
        linkUrl: `${process.env.REACT_APP_PELA_STORE_URL}/pages/become-a-retailer`,
    },
    {
        linkHover: "Affiliate Program",
        linkText: "Affiliate Program",
        linkUrl: "https://www.shareasale.com/shareasale.cfm?merchantID=81303",
    },
]

const footerSocialLinks: FooterSocialLink[] = [
    {
        icon: "Facebook",
        linkHover: "Visit our Facebook page",
        linkUrl: "https://www.facebook.com/PELACase",
    },
    {
        icon: "Pinterest",
        linkHover: "Visit our Pinterest page",
        linkUrl: "https://www.pinterest.ca/pelacase",
    },
    {
        icon: "Twitter",
        linkHover: "Visit our Twitter page",
        linkUrl: "https://twitter.com/pelacase",
    },
    {
        icon: "Instagram",
        linkHover: "Visit our Instagram page",
        linkUrl: "https://www.pinterest.ca/pelacase",
    },
    {
        icon: "Mail",
        linkHover: "Contact us by email",
        linkUrl: "mailto:info@pelacase.com",
    },
];

export default function Footer() {
    const classes = useStyles();
    return (
        <>
            <div className={classes.fullWidth}>
                <div className={classes.logoContainer}>
                    <img className={classes.image} src={pelaLogo} />
                </div>
                <Typography variant="body1" className={classes.textContainer}>
                    <strong>Everyday Products Without Everyday Waste&trade;</strong>
                </Typography>
            </div>
            <footer className={classes.footer}>
                <div className={classes.fullWidth}>
                    <Grid container direction="row-reverse">
                        {/* Social Links */}
                        <Grid item xs={12} md={4} className={classes.socialLinks}>
                            {
                                footerSocialLinks.map((socialLink, index) => {
                                    return (
                                        <a href={socialLink.linkUrl} aria-label={socialLink.linkHover} title={socialLink.linkHover} key={index}>
                                            <SocialIcon icon={socialLink.icon} />
                                        </a>
                                    );
                                })
                            }
                        </Grid>
                        {/* Navigation Links */}
                        <Grid item xs={12} md={8} >
                            <Grid container>
                                {
                                    footerNavigationLinks.map((navigationLink, index) => {
                                        const { linkText, linkUrl, linkHover } = navigationLink;
                                        return (
                                            <Grid item xs={12} sm={12} md="auto" key={index} className={classes.navLinkGrid}>
                                                <a href={linkUrl} title={linkHover} aria-label={linkHover} className={classes.navLink}>{linkText} </a>
                                            </Grid>
                                        );
                                    })
                                }
                            </Grid>
                            {/* Payment Types */}
                            <div className={classes.paymentImages}>
                                {
                                    paymentTypes.map((paymentType, index) => {
                                        const { image } = paymentType;
                                        return (
                                            <div className={classes.paymentImageWrap} key={index}>
                                                <img src={image.url} alt={image.alt} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item xs={12} md={6}>
                            {
                                process.env.REACT_APP_KLAVIYO_EMAIL_LIST_ID &&
                                <div className={classes.emailSignupContainer}>
                                    <Typography variant="h6" className={classes.emailSignupHeading}>{"Sign-Up for Sustainability Saturdays!"}</Typography>
                                    <Typography variant="caption" className={classes.emailSignupText}>{"We deliver the 5 best things from around the internet every Saturday morning before brunch."}</Typography>
                                    <EmailSignup klaviyoListId={process.env.REACT_APP_KLAVIYO_EMAIL_LIST_ID} />
                                </div>
                            }
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.corpLinks}>
                            {/* Corporate Partner Links */}
                            {
                                footerPartnerLinks.map((partnerLink, index) => {
                                    return (
                                            <a
                                                key={index}
                                                href={partnerLink.linkUrl}
                                                title={partnerLink.linkHover}
                                                aria-label={partnerLink.linkHover}
                                                target="_blank"
                                                rel="noopener"
                                                className={classes.corpLink}
                                            >
                                                <img
                                                    src={partnerLink.imageSrc}
                                                    alt={partnerLink.linkHover}
                                                    height="100"
                                                />
                                            </a>
                                    );
                                })
                            }
                        </Grid>
                    </Grid>
                    {/* Policy Links & Copyright */}
                    <Grid container direction="row-reverse">
                        <Grid item xs={12} md={8} className={classes.policyLinks}>
                            {
                                footerPolicyLinks.map((policyLink, index) => {
                                    return (
                                        <a key={index} href={policyLink.linkUrl}>
                                            <Typography variant="body2">{policyLink.linkText}</Typography>
                                        </a>
                                    );
                                })
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="body2" className={classes.copyright}>Copyright &copy; {(new Date()).getFullYear()} Pela Case</Typography>
                        </Grid>
                    </Grid>
                </div>
            </footer>
        </>
    )
}
