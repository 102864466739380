import { Builder, builder } from '@builder.io/react';
import VideoSection from './videoSection';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(VideoSection, {
    name: "Video Section",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "title",
            type: "string",
        },
        {
            name: "videoUrl",
            type: "string",
            defaultValue: "https://www.youtube.com/watch?v=8SmA98pHxYg",
        },
        {
            name: "autoplay",
            type: "boolean",
        },
        {
            name: "fullWidth",
            type: "boolean",
        },
    ]
})
