import React from "react";
import { BannerImageProps } from "./bannerImageProps";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    bannerImage: (props: BannerImageProps) => ({
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% top',
        backgroundSize: 'cover',
        backgroundImage: `url(${props.desktopImage})`,
        height: '100%',
    }),
    bannerContainer: {
        position: 'relative',
        height: 'calc(80vh)',
        minHeight: '500px',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(60vh)',
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: 'calc(50vh)',
            height: 'calc(62vh)',
        },
    },
    twoColBanner: {
        marginBottom: theme.spacing(4),
    },
    innerContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
    },
    twoColumnBannerImage : (props: BannerImageProps) => ({
        height: 'calc(75vh)',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url(${props.desktopImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.only('sm')]: {
            height: 'calc(45vh)',
        },
        [theme.breakpoints.only('xs')]: {
            height: 'calc(30vh)',
        },
    }),
    twoColumnTextBackground : (props: BannerImageProps) => ({
        background: props.backgroundColor ? props.backgroundColor : theme.palette.grey[100],
    }),
}));

export default function BannerImage(props: BannerImageProps) {
    const classes = useStyles(props);
    const { layout } = props;
    if(layout == 'One Column'){
        return (
            <Grid container className={classes.bannerContainer}>
                <div className={classes.innerContainer}>
                    <div className={classes.bannerImage}>
                        {props.render && props.render()}
                    </div>
                </div>
            </Grid>
        );
    }
    return (
        <Grid container className={classes.twoColBanner}>
            <Box clone order={{ xs: 2, md: 1 }}>
                <Grid item xs={12} md={6} className={classes.twoColumnTextBackground}>
                    {props.render && props.render()}
                </Grid>
            </Box>
            <Box clone order={{ xs: 1, md: 2 }}>
                <Grid item xs={12} md={6} className={classes.twoColumnBannerImage}>
                </Grid>
            </Box>
        </Grid>
    );
};
