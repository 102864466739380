import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { EmailSignupProps } from "./emailSignupProps";
import { TextField, Grid, Button } from "@material-ui/core";
import KlaviyoClient from "../../clients/klaviyoClient";
import EmailValidator from "../../utilities/emailValidator";

const useStyles = makeStyles(theme => ({
    input: {
        color: "#000",
        background: "#fff",
    },
    subscribedMessage: {
        color: theme.palette.primary.main,
    },
}));

export default function EmailSignup(props: EmailSignupProps) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        emailAddress: '',
        isSubscribing: false,
        error: '',
        message: '',
    });

    const setEmailAddress = (emailAddress: string) => {
        setState((prevState) => {
            return { ...prevState, emailAddress };
        });
    };

    const clearError = () => {
        setState((prevState) => {
            return { ...prevState, error: ''};
        });
    };

    const hasError = () => {
        return state.error.length > 0;
    };

    const hasMessage = () => {
        return state.message.length > 0;
    };

    const getHelperText = () => {
        return hasError() ? state.error : state.message;
    };

    const subscribeEmailAddress = async () => {
        if (state.isSubscribing) {
            return;
        }
        if (EmailValidator.isValid(state.emailAddress)) {
            try {
                setState((prevState) => {
                    return { ...prevState, isSubscribing: true };
                });
                const klaviyo = new KlaviyoClient();
                await klaviyo.subscribeEmail(state.emailAddress, props.klaviyoListId);
                setState((prevState) => {
                    return {
                        ...prevState,
                        emailAddress: '',
                        isSubscribing: false,
                        error: '',
                        message: 'Subscribed!  Check your email for confirmation'
                    };
                });
            } catch (error) {
                console.error(error);
                setState((prevState) => {
                    return { ...prevState, error: 'Error subscribing!'};
                });
            }
            setState((prevState) => {
                return { ...prevState, isSubscribing: false };
            });
        } else {
            setState((prevState) => {
                return { ...prevState, error: 'Enter a valid email address' };
            })
        }
    };

    return (
        <Grid container alignItems="flex-start" spacing={1}>
            <Grid item xs={12} sm={8} lg={6} xl={4}>
                <TextField
                    value={state.emailAddress}
                    type="email"
                    size="small"
                    variant="outlined"
                    placeholder="Your email address"
                    fullWidth
                    InputProps={{
                        className: classes.input,
                    }}
                    onChange={(event: any) => {
                        const newValue = event.target.value;
                        setEmailAddress(newValue);
                        if (EmailValidator.isValid(newValue)) {
                            clearError();
                        }
                    }}
                    FormHelperTextProps={{
                        classes: {
                            root: hasMessage() ? classes.subscribedMessage : undefined,
                        }
                    }}
                    error={hasError()}
                    helperText={getHelperText()}
                />
            </Grid>
            <Grid item xs={12} sm={4} lg={4} xl={3}>
                <Button
                    variant="contained"
                    fullWidth
                    size="large"
                    onClick={subscribeEmailAddress}
                >
                    Submit
                </Button>
            </Grid>
        </Grid>
    );
}
