import { Builder, builder } from '@builder.io/react';
import BuilderLimitedInventoryCollection from './builderLimitedInventoryCollection';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(BuilderLimitedInventoryCollection, {
    name: "Limited Inventory Collection",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "collectionHandle",
            type: "string",
            required: true,
        },
        {
            name: "allClaimedTag",
            type: "string",
        },
        {
            name: "saleStartTime",
            type: "number",
            required: true,
        },
        {
            name: "saleFinishTime",
            type: "number",
            required: true,
        },
        {
            name: "unitsAvailable",
            type: "number",
            required: true,
        },
        {
            name: "discountPercent",
            type: "number",
        },
        {
            name: "useViewProductButton",
            type: "boolean",
        },
        {
            name: "title",
            type: "string",
        },
        {
            name: "descriptionHtml",
            type: "richText",
        },
        {
            name: "alwaysShowButton",
            type: "boolean",
        },
    ]
});
