import React from "react";
import { Container, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BuilderProductCollectionProps } from "./builderProductCollectionProps";
import ProductCollectionItem from "./productCollectionItem";
import DescriptionHtml from "../../descriptions/descriptionHtml";
import FixedWidthCenteredContent from "../../mainContent/fixedWidthCenteredContent";
import ButtonLink from "../../buttonLink/buttonLink";

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 0),
        textAlign: 'center',
    },
    productThumbnailContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: '10px',
            boxShadow: '0px 0px 8px 1px rgba(230,230,230,1)',
        },
    },
    button: {
        textAlign: 'center',
        margin: theme.spacing(3, 'auto', 0),
    }
}));

export default function BuilderProductCollection(props: BuilderProductCollectionProps) {
    const classes = useStyles();
    const { title,
        descriptionHtml,
        useViewProductButton,
        productList,
        urlParameterString,
        alwaysShowButton,
        excludeVariants,
        buttonText,
        buttonLink,
        showNumbers } = props;

    if (productList.length > 0) {
        return (
            <FixedWidthCenteredContent>
                <div className={classes.container}>
                    { title &&
                        <Typography variant="h3">{title}</Typography>
                    }
                    { descriptionHtml &&
                        <DescriptionHtml html={descriptionHtml} />
                    }
                    <Grid container spacing={4} alignItems="stretch" justify="center">
                        {
                            productList.map((productDetails, index) => {
                                const showAsSoldOut = productDetails.productStatus === "Sold Out";
                                const showAsLowStock = productDetails.productStatus === "Low Stock";
                                return (
                                    <Grid item key={`${productDetails.productHandle}-${index}`} xs={12} sm={6} md={4} lg={3}>
                                        <div className={classes.productThumbnailContainer}>
                                            <ProductCollectionItem
                                                excludeVariants={excludeVariants}
                                                handle={productDetails.productHandle}
                                                title={productDetails.productTitle}
                                                description={productDetails.description}
                                                urlParameterString={urlParameterString}
                                                useViewProductButton={useViewProductButton}
                                                alwaysShowButton={alwaysShowButton}
                                                showAsSoldOut={showAsSoldOut}
                                                showAsLowStock={showAsLowStock}
                                                index={showNumbers ? index+1 : 0}
                                            />
                                        </div>
                                    </Grid>
                                );
                            })
                        }
                    </Grid>
                    {buttonText && buttonLink && <div className={classes.button}><ButtonLink text={buttonText} url={buttonLink} primary /></div>}
                </div>
            </FixedWidthCenteredContent>
        );
    } else {
        return null;
    }
}
