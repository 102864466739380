import React from "react";
import VideoEmbed from "./videoEmbed";
import { makeStyles } from '@material-ui/core/styles';
import { VideoSectionProps } from "./videoSectionProps";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";
import FullWidthContent from "../mainContent/fullWidthContent";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    containerFullWidth: {
        margin: theme.spacing(0, 0, 8),
    },
    title: {
        textAlign: 'center',
    },
    video: {
        margin: '30px auto 0',
        [theme.breakpoints.up('lg')]: {
            height: '700px',
        },
        [theme.breakpoints.only('md')]: {
            height: '600px',
        },
        [theme.breakpoints.only('sm')]: {
            height: '350px',
        },
        [theme.breakpoints.only('xs')]: {
            height: '250px',
        },
    },
    videoFullWidth: {
        marginTop: '0',
    },
}));

export default function VideoSection(props: VideoSectionProps) {
    const classes = useStyles();
    const { title, fullWidth, autoplay, videoUrl } = props;
    const videoClass = fullWidth ? `${classes.video} ${classes.videoFullWidth}` : classes.video;
    if(!videoUrl) {
        return null;
    }
    if (fullWidth) {
        return (
            <FullWidthContent>
                <div className={classes.containerFullWidth}>
                    <Typography variant="h3" align="center">{title}</Typography>
                    <div className={videoClass}>
                        <VideoEmbed autoplay={autoplay} videoUrl={videoUrl} />
                    </div>
                </div>
            </FullWidthContent>
        )
    }
    return (
        <FixedWidthCenteredContent>
          <div className={classes.container}>
            <Typography variant="h3" align="center">{title}</Typography>
            <div className={videoClass}>
                <VideoEmbed autoplay={autoplay} videoUrl={videoUrl} />
            </div>
        </div>
        </FixedWidthCenteredContent>
      );
}
