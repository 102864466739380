import React from "react";
import ShowCaseProps from "./showCaseProps";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ButtonLink from '../buttonLink/buttonLink';
import DescriptionHtml from "../html/htmlBlock";
import { Typography } from "@material-ui/core";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";
import VideoEmbed from "../video/videoEmbed";

const useStyles = makeStyles(theme => ({
    twoColumnShowCase: {
        margin: theme.spacing(4, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 2),
        },
    },
    column1: {
        textAlign: 'center',
    },
    imageContainer: {
        '& iframe': {
            height: '330px',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
        [theme.breakpoints.only('xs')]: {
            '& iframe': {
                height: '250px',
            },
        },
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    title: {
        marginTop: '10px',
    },
    textAlign: {
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left'
        },
    },
    builderImage: {
        width: '100%',
    }
}));

export default function TwoColumnShowCase(props: ShowCaseProps) {
    const classes = useStyles();
    const { preTitle, title, description, linkText, linkUrl, image, videoUrl, textRight} = props;
    return (
        <FixedWidthCenteredContent>
            <div className={classes.twoColumnShowCase}>
                <Grid container spacing={3} direction={textRight ? "row-reverse" : undefined} alignItems="center">
                    <Grid item xs={12} sm={12} md={6} className={classes.column1}>
                        <div className={classes.imageContainer}>
                            {
                                videoUrl ?
                                <VideoEmbed videoUrl={videoUrl} autoplay={false} />
                                :
                                <>
                                    {image && <img src={image} className={classes.builderImage} />}
                                </>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.textAlign}>
                        <Typography variant="overline">{preTitle}</Typography>
                        <Typography variant="h2" className={classes.title}>{title}</Typography>
                        <DescriptionHtml html={description} className={classes.textAlign} />
                        {linkText && <ButtonLink text={linkText} url={linkUrl} primary />}
                    </Grid>
                </Grid>
            </div>
        </FixedWidthCenteredContent>
    );
};
