import React from "react";
import colourToHex from "../../utilities/colourToHex";
import ColourSwatch from "./colourSwatch";
import { ProductSwatchProps } from "./productSwatchProps";
const { getColour } = require('../../utilities/shopifyProductHelper.js');

export default function ProductSwatch(props: ProductSwatchProps) {
    const { product, selected, onClick } = props;
    const colour = getColour(product); // eg. "moss"
    const htmlColor = colourToHex(colour); // eg. #45f210
    return (
        <ColourSwatch
            selected={selected}
            htmlColour={htmlColor}
            onClick={(event) => onClick && onClick(event)}
        />
    );
}
