import { Builder, builder } from '@builder.io/react';
import BuilderButtonAndText from './builderButtonAndText';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(BuilderButtonAndText, {
    name: "HTML and Optional Buttons",
    friendlyName: "Text and Optional Buttons",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "text",
            type: "richText",
        },
        {
            name: "backgroundColor",
            type: "color",
            defaultValue: "#FFFFFF",
        },
        {
            name: "buttons",
            type: "list",
            required: true,
            subFields: [
                {
                    name: "buttonText",
                    type: "string",
                },
                {
                    name: "url",
                    type: "string",
                },
                {
                    name: "green",
                    type: "boolean",
                    helperText: "Should the button be green or white."
                },
            ]
        },
    ]
})
