import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FullWidthContentProps } from './fullWidthContentProps';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
    },
}));

export default function FullWidthContent(props: FullWidthContentProps) {
    const classes = useStyles();
    return <div className={classes.fullWidth}>{props.children}</div>;
}
