import React from "react";
import { GuaranteeIconProps } from "./guaranteeIconProps";
import Loop from "@material-ui/icons/Loop";
import Eco from "@material-ui/icons/Eco";
import Smartphone from "@material-ui/icons/Smartphone";
import Mood from "@material-ui/icons/Mood";
import Security from '@material-ui/icons/Security';
import Public from '@material-ui/icons/Public'
import Waves from '@material-ui/icons/Waves'

export default function GuaranteeIcon(props: GuaranteeIconProps) {
    const { iconType, color } = props;
    const colorHex = color && color.hex ? color.hex : '#000000';

    switch (iconType) {
        case 'Recycle Loop':
            return <Loop fontSize="large" style={{color: colorHex}}/>;
        case 'Leaf':
            return <Eco fontSize="large" style={{color: colorHex}} />;
        case 'Mobile Phone':
            return <Smartphone fontSize="large" style={{color: colorHex}} />;
        case 'Happy Face':
            return <Mood fontSize="large" style={{color: colorHex}} />;
        case 'Security Shield':
            return <Security fontSize="large" style={{color: colorHex}} />;
        case 'Globe':
            return <Public fontSize="large" style={{color: colorHex}} />;
        case 'Ocean Waves':
            return <Waves fontSize="large" style={{color: colorHex}} />;
        default:
            console.error(`Guarantee icon not found: ${iconType}`);
            return null;
    }
}
