import { Builder, builder } from '@builder.io/react';
import BuilderProductBlock from './builderProductBlock';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(BuilderProductBlock, {
    name: "Product Buy Block",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "handle",
            type: "string",
            required: true,
        },
        {
            name: "urlParameterString",
            type: "string",
        },
        {
            name: "title",
            type: "string",
        },
        {
            name: "descriptionHtml",
            type: "richText",
        },
        {
            name: "mobileHtml",
            type: "richText",
        },
        {
            name: "landingPageOnATC",
            type: "string",
        },
        {
            name: "image",
            type: "file",
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
        }
    ]
});
