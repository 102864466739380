import React, { useEffect, useState } from "react";
import { ShopifyProduct } from "../../models/shopifyProduct";
import ProductQuery from "./productQuery";
import { ProductSingleQueryProps } from "./productSingleQueryProps";

export default function ProductSingleQuery(props: ProductSingleQueryProps) {
    const [product, setProduct] = useState<ShopifyProduct | undefined>(undefined);

    const onData = (product: ShopifyProduct) => {
        setProduct(product);
    };

    useEffect(() => {
        if (product) {
            props.onProductsLoaded([product])
        }
    }, [product]);

    return (
        <ProductQuery handle={props.handle} onData={onData} />
    )
}
