import { Builder, builder } from '@builder.io/react';
import ImageCarousel from './imageCarousel';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(ImageCarousel, {
    name: "Image Carousel and Optional Links",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "title",
            type: "string",
            required: true,
        },
        {
            name: "slidesToShow",
            type: "number",
            defaultValue: 4,
            required: true,
        },
        {
            name: "mediaList",
            type: "list",
            defaultValue: [
                { title: "Image 1" }
            ],
            subFields: [
                {
                    name: "title",
                    type: "string",
                    required: true,
                },
                {
                    name: "descriptionHtml",
                    type: "richText"
                },
                {
                    name: "preTitle",
                    type: "string",
                },
                {
                    name: "linkText",
                    type: "string",
                },
                {
                    name: "linkUrl",
                    type: "string",
                    required: true,
                },
                {
                    name: "openInNewTab",
                    type: "boolean",
                    defaultValue: false,
                },
                {
                    name: "image",
                    type: "file",
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                    required: true,
                }
            ]
        },
    ]
})
