import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ProductBadgeProps } from "./productBadgeProps";

const BADGE_DIAMETER = '64px';

const useStyles = makeStyles(theme => ({
    badgeCircle: {
        width: BADGE_DIAMETER,
        height: BADGE_DIAMETER,
        borderRadius: '50%',
        lineHeight: BADGE_DIAMETER,
        fontSize: '0.75rem',
        backgroundColor: theme.palette.grey[100],
        textAlign: 'center',
        color: theme.palette.grey[900],
        marginBottom: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        '& svg': {
            marginTop: '12px',
        },
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    red: {
        backgroundColor: theme.palette.error.main,
        color: 'white',
    }
}));

export default function ProductBadge(props: ProductBadgeProps) {
    const { children, toolTipText, color } = props;
    const classes = useStyles();

    const badgeClass = color === 'green' ? `${classes.badgeCircle} ${classes.green}` : color === 'red' ? `${classes.badgeCircle} ${classes.red}` : classes.badgeCircle
    return (
        <div className={badgeClass} title={toolTipText}>{children}</div>
    );
}
