import React from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionHtml from "../descriptions/descriptionHtml";
import { Container, Typography } from "@material-ui/core";
import { getProductPriceInfo } from "../../utilities/productPriceCalculator";
import { getProductRelativeUrl } from '../../utilities/linkHelper';
import ProductSwatch from "./productSwatch";
import ProductPrice from "../product/productPrice/productPrice";
import HtmlBlock from "../html/htmlBlock";
import SmartLink from "../links/smartLink";
import AddToCartButton from "../buttons/addToCartButton";
import { ProductBuyBlockProps } from "./productBuyBlockProps";

const ADD_TO_CART_BUTTON_HEIGHT = '40px';

const useStyles = makeStyles(theme => ({
    imageLinkContainer: {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        color: '#000',
        textDecoration: 'none',
        width: '100%',
    },
    label: {
        textTransform: 'uppercase',
        display: 'block',
    },
    twoColumnBuyBlock: {
        margin: theme.spacing(4, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 2),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(1, 0),
        }
    },
    img: {
        width: "100%",
    },
    column1: {
        textAlign: "center",
    },
    imageContainer: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto',
        },
    },
    noImage: {
        width: '80%',
        height: '300px',
        textAlign: 'center',
        background: theme.palette.grey[100],
        fontSize: '0.8rem',
        color: '#ddd',
        lineHeight: '300px',
        overflow: 'hidden',
        margin: '0 auto',
    },
    icon: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    title: {
        margin: '10px 0px',
    },
    description: {
        marginBottom: '5px',
    },
    productTitle: {
        margin: '10px 0',
        fontWeight: theme.typography.fontWeightMedium,
    },
    price: {
        textAlign: 'left',
    },
    nameToggle: {
        width: '100%',
        margin: '5px 0',
    },
    outOfStock: {
        color: theme.palette.error.main,
        width: '100%',
        margin: '10px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        height: ADD_TO_CART_BUTTON_HEIGHT,
        lineHeight: ADD_TO_CART_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    textAlign: {
        textAlign: 'left',
    },
    mobileHtml: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        }
    },
    desktopHtml: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    link: {
        textDecorationColor: theme.palette.primary.main,
    },
}));

export default function ProductBuyBlock(props: ProductBuyBlockProps) {
    const classes = useStyles();
    const {
        defaultProductHandle,
        preTitle,
        title,
        descriptionHtml,
        mobileHtml,
        image,
        urlParameterString,
        discountPercent,
        landingPageOnATC,
        products,
        backgroundColorHex,
        useFeatureImage,
        footnote,
        useProductTitle,
        disableProductLink,
    } = props;
    const [state, setState] = React.useState({
        selectedProductIndex: 0,
    });

    const setSelectedProductIndex = (selectedProductIndex: number) => {
        setState((prevState) => {
            return { ...prevState, selectedProductIndex };
        });
    };

    if (products.length) {
        const selectedProduct = products[state.selectedProductIndex];
        const selectedProductPriceInfo = getProductPriceInfo(selectedProduct);
        const isOutOfStock = !selectedProduct.availableForSale;
        const isPreOrder = !!(selectedProduct.tags && selectedProduct.tags.some(tag => tag === 'Preorder'));
        const imageCount = selectedProduct.images ? selectedProduct.images.length : 0;
        const firstImage = imageCount > 0 && selectedProduct.images ? selectedProduct.images[0] : undefined;
        const backgroundColor = backgroundColorHex ? backgroundColorHex : '#ffffff';

        return (
            <div style={{ backgroundColor: backgroundColor }}>
                <Container fixed key={`single-buy-${defaultProductHandle}`}>
                    <Grid className={classes.twoColumnBuyBlock} container alignItems="center" justify="center">
                        <Grid item container spacing={3} direction="row-reverse" alignItems="center">
                            <Grid item xs={12} md={6} className={classes.column1}>
                                <div className={classes.imageLinkContainer}>
                                    <div className={classes.imageContainer}>
                                        { useFeatureImage ?
                                            <img className={classes.img} src={image} />
                                            :
                                            <>
                                                {
                                                    firstImage
                                                    ?
                                                        <span>
                                                            {/* See https://github.com/gatsbyjs/gatsby/pull/17006 for why presentationWidth is used here */}
                                                            <img className={classes.img} src={firstImage.src} alt={firstImage.altText} />
                                                        </span>
                                                    :
                                                        <div className={classes.noImage}>No Image</div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </Grid>
                            <Grid item container xs={12} md={6}>
                                <Grid item container spacing={2}>
                                    { preTitle &&
                                        <Grid item xs={12} className={classes.textAlign}>
                                            <Typography variant="overline">{preTitle}</Typography>
                                        </Grid>
                                    }
                                    { useProductTitle &&
                                        <Grid item xs={12} className={classes.textAlign}>
                                            <Typography variant="h2" className={classes.productTitle}>{selectedProduct.title}</Typography>
                                        </Grid>
                                    }
                                    { title &&
                                        <Grid item xs={12} className={classes.textAlign}>
                                            <Typography variant="h2" className={classes.title}>{title}</Typography>
                                        </Grid>
                                    }
                                    { descriptionHtml &&
                                        <Grid item xs={12} className={classes.textAlign}>
                                            <HtmlBlock html={descriptionHtml} className={mobileHtml ? classes.desktopHtml : ''}/>
                                            { mobileHtml &&
                                                <HtmlBlock className={classes.mobileHtml} html= {mobileHtml} />
                                            }
                                        </Grid>
                                    }
                                    { products.length > 1 && // Only show swatches when there are multiple products
                                        <Grid item xs={12}>
                                            {
                                                <>
                                                    <Typography variant="caption" className={classes.label}>Color</Typography>
                                                    {
                                                        products.map((product, productIndex) => {
                                                            return (
                                                                <ProductSwatch
                                                                    key={productIndex}
                                                                    product={product}
                                                                    selected={state.selectedProductIndex === productIndex}
                                                                    onClick={() => setSelectedProductIndex(productIndex)}
                                                                />
                                                            );
                                                        })
                                                    }
                                                </>
                                            }
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Typography variant="caption" className={classes.label}>Price</Typography>
                                        <ProductPrice productPriceInfo={selectedProductPriceInfo} displayMode="large-inline" productHandle={selectedProduct.handle} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {/* TODO: i18n */}
                                        {
                                            isOutOfStock
                                            ?
                                                // TODO: i18n
                                                <Typography variant="caption" className={classes.outOfStock}>
                                                    Out of Stock
                                                </Typography>
                                            :
                                                <AddToCartButton
                                                    isPreOrder={isPreOrder}
                                                    product={selectedProduct}
                                                    isOutOfStock={isOutOfStock}
                                                    primary
                                                    quantity={1}
                                                    urlParameterString={urlParameterString}
                                                />
                                        }
                                    </Grid>
                                    { footnote &&
                                        <Grid item xs={12}>
                                            <Typography variant="caption">{footnote}</Typography>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        );
    } else {
        return null;
    }
};
