import React from "react";
import { ImageBandProps } from "./imageBandProps";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    bg: {
        backgroundColor: theme.palette.grey[100],
        paddingBottom: '50px',
        paddingTop: '50px',
        margin: theme.spacing(4, 0),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    textCenter: {
        textAlign: 'center',
    },
    imageContainer: {
        margin: '40px',
        opacity: '0.5',
        [theme.breakpoints.up('md')]: {
            width: '120px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100px',
        },
    },
    builderImage: {
        width: '100%',
    }
}));

export default function ImageBand(props: ImageBandProps) {
    const classes = useStyles();
    const { title, description, backgroundColorHex, textColorHex, imageListItems } = props;
    if (!imageListItems || imageListItems.length === 0) {
        return null;
    }
    const backgroundColor = backgroundColorHex ? backgroundColorHex : '#f4f4f2';
    return (
        <Grid className={classes.bg} style={{ backgroundColor: backgroundColor }} container alignItems="center" justify="center">
            {
                title &&
                <Grid item xs={12} className={classes.textCenter}>
                    <Typography variant="overline" style={{ color: textColorHex ? textColorHex : 'black' }} className={classes.title}>{title}</Typography>
                </Grid>
            }
            {
                description &&
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.textCenter} style={{ color: textColorHex ? textColorHex : 'black' }}><i>{description}</i></Typography>
                </Grid>
            }
            {
                imageListItems.map((imageListItem, index) => {
                    return (
                        <Grid item key={index}>
                            <div className={classes.imageContainer}>
                                {
                                    imageListItem.linkUrl ?
                                    <a href={imageListItem.linkUrl} target="_blank" rel="noopener">
                                        <img src={imageListItem.image} className={classes.builderImage} />
                                    </a>
                                    :
                                    <img src={imageListItem.image} className={classes.builderImage} />
                                }
                            </div>
                        </Grid>
                    );
                })
            }
        </Grid>
    );
}
