import React from "react";
import { VideoEmbedProps } from "./videoEmbedProps";
import { makeStyles } from '@material-ui/core/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    thumbnailContainer: {
        position: 'relative',
        cursor: 'pointer',
        '&:hover $playButtonIcon': {
            color: '#D82D2D',
        },
    },
    playButtonContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    playButtonIcon: {
        fontSize: '80px',
        backgroundColor: '#fff',
        borderRadius: '50%',
    },
}));

const VIMEO_VIDEO_URL_REGEX = /^https:\/\/vimeo.com\/([0-9]+)$/;
const YOUTUBE_VIDEO_URL_REGEX = /^https:\/\/www.youtube.com\/watch\?v=([A-Z0-9a-z_\-]+)/;

const fixUrl = (videoUrl: string) => {
    if (videoUrl) {
        const matchesVimeo = videoUrl.match(VIMEO_VIDEO_URL_REGEX);
        if (matchesVimeo && matchesVimeo.length > 1) {
            return `https://player.vimeo.com/video/${matchesVimeo[1]}`;
        }

        const matchesYoutube = videoUrl.match(YOUTUBE_VIDEO_URL_REGEX);
        if (matchesYoutube && matchesYoutube.length > 1) {
            return `https://www.youtube.com/embed/${matchesYoutube[1]}`;
        }
    }
    return videoUrl;
};

export default function VideoEmbed(props: VideoEmbedProps) {
    const { thumbnailImage, autoplay } = props;
    const videoUrl = fixUrl(props.videoUrl);
    const [isThumbnailClicked, setThumbnailClicked] = React.useState(false);
    const classes = useStyles();
    if (thumbnailImage) {
        if (isThumbnailClicked) {
            return (
                <iframe
                    src={`${videoUrl}?autoplay=1`}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                >
                </iframe>
            );
        } else {
            return (
                <div className={classes.thumbnailContainer}>
                    <img src={thumbnailImage} />
                    <div className={classes.playButtonContainer}>
                        <IconButton
                            onClick={() => setThumbnailClicked(true)}
                            size="medium"
                            title="Click to play video"
                        >
                            <PlayCircleFilledIcon className={classes.playButtonIcon} />
                        </IconButton>
                    </div>
                </div>
            );
        }
    } else {
        return (
            <iframe
                src={`${videoUrl}${autoplay ? '?autoplay=1&mute=1&muted=1&rel=0' : '?rel=0'}`}
                frameBorder="0"
                width="100%"
                height="100%"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
            </iframe>
        );
    }
}
