import React from "react";
import { makeStyles } from "@material-ui/core";
import { DescriptionHtmlProps } from "./descriptionHtmlProps";

const useStyles = makeStyles(theme => ({
    description: {
        '&': theme.typography.body1,
        '& a': {
            color: '#000',
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
        },
        '& a:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

export default function DescriptionHtml(props: DescriptionHtmlProps) {
    const { className } = props;
    const classes = useStyles(props);
    const mergedClasses = className ? `${classes.description} ${className}` : classes.description;
    return (
        <div className={mergedClasses} dangerouslySetInnerHTML={{__html: props.html}}></div>
    );
}
