import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { ColourSwatchProps } from "./colourSwatchProps";

const SWATCH_DIAMETER = 24;
const SELECTED_INNER_MARGIN = 4;

const useStyles = makeStyles(theme => ({
    outerSelected: {
        width : `${SWATCH_DIAMETER-1}px`, // -1 for border
        height: `${SWATCH_DIAMETER-1}px`, // -1 for border
        border: 'solid 1px #333',
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px',
        marginTop: '3px',
        '& $swatch': {
            borderRadius: '50%',
            border: `solid 1px ${theme.palette.grey[300]}`,
            marginTop: `${SELECTED_INNER_MARGIN}px`,
            marginLeft: `${SELECTED_INNER_MARGIN}px`,
            width: `${SWATCH_DIAMETER - (SELECTED_INNER_MARGIN*2) - 3}px`,
            height: `${SWATCH_DIAMETER - (SELECTED_INNER_MARGIN*2) - 3}px`,
        },
    },
    outerDeselected: {
        border: `solid 1px ${theme.palette.grey[300]}`,
        borderRadius: '50%',
        display: 'inline-block',
        marginRight: '5px',
        marginTop: '3px',
        '& $swatch': {
            width : `${SWATCH_DIAMETER-1}px`, // -1 for border
            height: `${SWATCH_DIAMETER-1}px`, // -1 for border
            borderRadius: '50%',
        },
    },
    swatch: {},
}));

export default function ColourSwatch(props: ColourSwatchProps) {
    const { htmlColour, selected, onClick } = props;
    const classes = useStyles();
    const style: React.CSSProperties = {};
    if (onClick) {
        style.cursor = 'pointer';
    }
    return (
        <div
            onClick={(event) => onClick && onClick(event)}
            className={selected ? classes.outerSelected : classes.outerDeselected}
            style={style}
        >
            <div className={classes.swatch} style={{ backgroundColor: htmlColour }}></div>
        </div>
    );
}
