import React from "react";
import { FC } from "react";
import { Button, Container, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { ImageCarouselProps } from "./imageCarouselProps";
import SmartLink from "../links/smartLink";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DescriptionHtml from "../descriptions/descriptionHtml";

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 0),
        '& button': {
            zIndex: '1',
        },
        '& .slick-track': {
            display: 'flex !important',
            '& > div': {
                height: 'auto !important',
                margin: theme.spacing(2),
                [theme.breakpoints.only('xs')]: {
                    margin: theme.spacing(1),
                },
                '& > div': {
                    height: '100%',
                }
            },
        },
    },
    height: {
        height: '100%',
    },
    paper: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
    },
    grid: {
        height: '100%',
    },
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        '& > div': {
            transition: 'transform 250ms ease-out 0s',
            transform: 'scale(1)',
        }
    },
    imageContainerNoLink: {
        position: 'relative',
        overflow: 'hidden',
    },
    image: {
        width: '100%',
    },
    buttonLinkContainer: {
        textAlign: 'center',
        padding: theme.spacing(2),
        flex: '1 1 auto',
        '& h6': {
            margin: '0',
        },
        '& p': {
            margin: theme.spacing(1, 0),
            fontSize: '0.875rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.75rem',
            }
        },
        '& > div': {
            margin: '0',
        }
    },
    link: {
        textDecoration: 'none',
        '&:hover': {
            '& $imageContainer > img': {
                transform: 'scale(1.05)',
            },
        },
    },
    gridWrapper: {
        display: 'inline-block',
        width: '100%',
        height: '100%',
    },
    title: {
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
        },
    },
    slickPrev: {
        height: '40px',
        width: '40px',
        left: '-4px',
        [theme.breakpoints.down('md')]: {
            left: '0',
        },
        '&::before': {
            content: 'none',
        },
        '&.slick-disabled': {
            display: 'none !important',
        },
    },
    slickNext: {
        height: '40px',
        width: '40px',
        right: '-4px',
        [theme.breakpoints.down('md')]: {
            right: '0',
        },
        '&::before': {
            content: 'none',
        },
        '&.slick-disabled': {
            display: 'none !important',
        },
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        border: '1px solid white',
        cursor: 'pointer',
        transition: 'background 0.2s ease-out',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        '&:focus': {
            outline: 'none',
            backgroundColor: theme.palette.primary.dark,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
    buttonPrev: {
        padding: '7px 11px',
    },
    buttonNext: {
        padding: '7px 9px',
    },
    mainButton: {
        border: '1px solid black',
        fontSize: '0.875rem',
        marginTop: theme.spacing(1),
    }
}));

interface ArrowProps {
    className?: string;
    outerClass?: string;
    buttonClass?: string;
    style?: React.CSSProperties;
    onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    arrowIcon: JSX.Element;
}

function Arrow(props: ArrowProps) {
    const { className, style, onClick, outerClass, buttonClass, arrowIcon } = props;
    return (
        <div className={`${className} ${outerClass}`} style={{ ...style, zIndex: 1 }} onClick={onClick}>
            <button className={buttonClass}>
                {arrowIcon}
            </button>
        </div>
    );
}

const ImageCarousel : FC<ImageCarouselProps> = props => {
    const classes = useStyles();
    const { title, slidesToShow, mediaList } = props;
    if (!mediaList || mediaList.length === 0) {
        return null;
    }
    const prevArrow = <ArrowBackIosIcon />
    const nextArrow = <ArrowForwardIosIcon />

    const settings = {
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow ? slidesToShow : 3,
        slidesToScroll: 1,
        swipe: true,
        nextArrow: <Arrow outerClass={classes.slickNext} buttonClass={`${classes.button} ${classes.buttonNext}`} arrowIcon={nextArrow} />,
        prevArrow: <Arrow outerClass={classes.slickPrev} buttonClass={`${classes.button} ${classes.buttonPrev}`} arrowIcon={prevArrow} />,
        responsive: [
            {
              breakpoint: 960,
              settings: {
                infinite: true,
                slidesToShow: 1,
                centerPadding: '30px',
                centerMode: true,
              }
            },
        ]
    };

    return (
        <Container fixed>
            <div className={classes.container}>
                {
                    title &&
                    <Typography variant="h3" className={classes.title}>{title}</Typography>
                }
                <Slider {...settings}>
                    {
                        mediaList.map((item, index) => {
                            const { image, title, descriptionHtml, linkUrl, linkText, openInNewTab } = item;
                            return (
                                <div key={index} className={classes.height}>
                                    <div className={classes.gridWrapper}>
                                        <div className={classes.grid}>
                                            {
                                                linkUrl ?
                                                <SmartLink url={linkUrl} className={classes.link} openInNewTab={openInNewTab}>
                                                    <Paper elevation={1} className={classes.paper}>
                                                        <div className={classes.imageContainer}>
                                                            <img src={image} alt={title} className={classes.image} />
                                                        </div>
                                                        <Grid container justify="space-between" direction="column" className={classes.buttonLinkContainer}>
                                                            <Grid item>
                                                                <Typography variant="h6">
                                                                    {title}
                                                                </Typography>
                                                            </Grid>
                                                            {
                                                                descriptionHtml &&
                                                                <Grid item><DescriptionHtml html={descriptionHtml} /></Grid>
                                                            }
                                                            {linkText && <Grid item><Button className={classes.mainButton}>{linkText}</Button></Grid>}
                                                        </Grid>
                                                    </Paper>
                                                </SmartLink>
                                                :
                                                <Paper elevation={1}>
                                                    <div className={classes.imageContainerNoLink}>
                                                        <img src={image} alt={title} className={classes.image} />
                                                    </div>
                                                </Paper>
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </Slider>
            </div>
        </Container>
    );
};

export default ImageCarousel;
