import React from 'react';

export interface GlobalContextType {
    locale: string;
    currencyCode: string;
    storeUrl?: string;
}

const GlobalContext = React.createContext<GlobalContextType|undefined>(undefined);

export default GlobalContext;
