import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { BuilderButtonAndTextProps } from "./builderButtonAndTextProps";
import ButtonLink from "../buttonLink/buttonLink";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";
import HtmlBlock from "../html/htmlBlock";
import { Grid } from "@material-ui/core";
import FullWidthContent from "../mainContent/fullWidthContent";


const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 'auto'),
        
        textAlign: 'center',
    },
    containerPadding: {
        padding: theme.spacing(7, 0),
        '& h1, & h2, & h3, & h4': {
            marginTop: '0',
        }
    },
    button: {
        marginTop: theme.spacing(4),
    },
}));

export default function BuilderButtonAndText(props: BuilderButtonAndTextProps) {
    const classes = useStyles();
    const { text, backgroundColor, buttons } = props;
    return (
        <FullWidthContent>
            <div style={{backgroundColor: backgroundColor}}>
                <FixedWidthCenteredContent>
                    <div className={backgroundColor && backgroundColor != '#FFFFFF' ? `${classes.containerPadding} ${classes.container}` : classes.container}>
                        <HtmlBlock html={text} />
                        <Grid container justify="center" spacing={4}>
                            {
                                buttons && buttons.length && buttons.map((button, index) => {
                                    return (
                                        <Grid item key={index}>
                                            {
                                                button.buttonText &&
                                                <div className={classes.button}>
                                                    <ButtonLink primary={button.green} text={button.buttonText} url={button.url} />
                                                </div>
                                            }
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </div>
                </FixedWidthCenteredContent>
            </div>
        </FullWidthContent>
    );
};
