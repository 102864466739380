import { Builder, builder } from '@builder.io/react';
import ReviewsSection from './reviewsSection';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(ReviewsSection, {
    name: "Reviews Section",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "title",
            type: "string",
        },
        {
            name: "buttonText",
            type: "string",
        },
        {
            name: "buttonLink",
            type: "string",
        },
        {
            name: "reviews",
            type: "list",
            required: true,
            subFields: [
                {
                    name: "title",
                    type: "string",
                },
                {
                    name: "name",
                    type: "string"
                },
                {
                    name: "review",
                    type: "string",
                },
                {
                    name: "image",
                    type: "file",
                    allowedFileTypes: ['jpeg', 'jpg', 'png'],
                }
            ]
        },
    ]
})
