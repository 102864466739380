import React, { useEffect } from "react";
import { ScriptProps } from "./scriptProps";
import {Helmet} from "react-helmet";

  
export default function Script(props: ScriptProps) {
    const { headScript, isBodyScript, idDiv, isSource } = props;

    let effectCanceled = false;
    useEffect(() => {
        if (isBodyScript && !effectCanceled) {
            const script = document.createElement('script');
            if (isSource) {
                script.src = headScript;
            } else { // !isSource
                script.innerText = headScript;
            }
            document.body.appendChild(script);
        }
        return () => { effectCanceled = true; }
    }, []);

    return (
        <div>
            {
                !isBodyScript && 
                <Helmet>
                    {
                        isSource ?
                        <script src={headScript}></script>
                        :
                        <script type="text/javascript">{headScript}</script>
                    }
                </Helmet>
            }
            {
                idDiv &&
                <div dangerouslySetInnerHTML={{__html: idDiv}}></div>
            }
        </div>
    );
};
