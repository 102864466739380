import { Builder, builder } from '@builder.io/react';
import BuilderReferralCollection from './builderReferralCollection';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");

Builder.registerComponent(BuilderReferralCollection, {
    name: "Free Case Referral Collection",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "collectionHandle",
            type: "string",
            required: true,
        },
        {
            name: "freeProductTokenHandle",
            type: "string",
            required: true,
        },
        {
            name: "title",
            type: "string",
        },
        {
            name: "descriptionHtml",
            type: "richText",
        },
        {
            name: "alwaysShowButton",
            type: "boolean",
        },
        {
            name: "claimButtonLabel",
            type: "string",
        },
    ]
});
