import React, { useEffect, useState } from "react";
import { useQuery, gql } from '@apollo/client';
import { ShopifyProduct } from "../../models/shopifyProduct";
import { ProductGroupQueryProps } from "./productGroupQueryProps";
import ProductQuery from "./productQuery";

const GET_PRODUCT_GROUP_DETAILS = gql`
  query ProductGroupQuery($handle: String!) {
    productByHandle(handle: $handle) {
        id
        handle
        metafields(identifiers: [{key: "colour", namespace: "demac"}, {key: "handles", namespace: "demac"}]) {
            namespace
            key
            value
        }
    }
  }
`;

export default function ProductGroupQuery(props: ProductGroupQueryProps) {
    const { loading, data } = useQuery(GET_PRODUCT_GROUP_DETAILS, {
        variables: { handle: props.handle },
    });
    const [products, setProducts] = useState<ShopifyProduct[]>([]);
    const [productsNotFound, setProductsNotFound] = useState<string[]>([]);

    const onData = (product: ShopifyProduct) => {
        setProducts(oldState => [...oldState, product]);
    };

    const onNotFound = (handle: string) => {
        setProductsNotFound(productsNotFound.concat([handle]));
    }

    useEffect(() => {
        if (data && data.productByHandle) {
            const handleMeta = (data?.productByHandle?.metafields as any[]).filter(meta => meta?.key === "handles");
            const handles: string[] = handleMeta?.length ? handleMeta[0].node.value.split("|") : [];

            if (products?.length && products.length === handles.filter(handle => !productsNotFound.includes(handle)).length) {
                props.onProductsLoaded(products);
            }
        }
    }, [products, productsNotFound]);

    if (loading) {
        return null;
    } else {
        if (data && data.productByHandle) {
            const handleMeta = (data?.productByHandle?.metafields as any[]).filter(meta => meta?.key === "handles");
            const handles: string[] = handleMeta?.length ? handleMeta[0].node.value.split("|") : [];
            return (
                <>
                    {
                        handles.map((handle: string) => {
                            return <ProductQuery key={handle} handle={handle} onData={onData} onNotFound={onNotFound} />
                        })
                    }
                </>
            );
        } else {
            return null;
        }
    }
}
