import React, { useState } from "react";
import { ShopifyProduct } from "../../models/shopifyProduct";
import ProductSingleQuery from "../product/productSingleQuery";
import { BuilderReferralCollectionProps } from "./builderReferralCollectionProps";
import ReferralCollection from "./referralCollection";

export default function BuilderReferralCollection(props: BuilderReferralCollectionProps) {
    const [freeProduct, setFreeProduct] = useState<ShopifyProduct[]>([]);
    const { collectionHandle, freeProductTokenHandle, title, claimButtonLabel, descriptionHtml, alwaysShowButton } = props;

    const onFreeProductLoaded = (childProducts: ShopifyProduct[]) => {
        setFreeProduct(childProducts);
    };

    if (collectionHandle && freeProductTokenHandle) {
        return (
            <>
                <ProductSingleQuery handle={freeProductTokenHandle} onProductsLoaded={onFreeProductLoaded} />
                <ReferralCollection
                    handle={collectionHandle}
                    freeProduct={freeProduct && freeProduct.length ? freeProduct[0] : undefined}
                    claimButtonLabel={claimButtonLabel}
                    alwaysShowButton={alwaysShowButton}
                    descriptionHtml={descriptionHtml}
                    title={title}
                />
            </>
        );
    } else {
        return null;
    }
}
