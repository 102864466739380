import { ShopifyProduct } from "../models/shopifyProduct";
import currency from 'currency.js';

export interface ProductPriceInfo {
    isDiscounted: boolean;
    price: currency;
    compareAtPrice?: currency;
};

const getProductPriceInfo = (product: ShopifyProduct): ProductPriceInfo => {
    const priceAmount = product.priceRange?.minVariantPrice.amount;
    const price = currency(parseFloat(priceAmount));
    const compareAtPriceAmount = product.variants.length > 0 ? product.variants[0].compareAtPrice?.amount : 0;
    const compareAtPrice = compareAtPriceAmount ? currency(parseFloat(compareAtPriceAmount)) : currency(0);
    if (compareAtPrice.value > 0 && price.value < compareAtPrice.value) {
        return {
            isDiscounted: true,
            price,
            compareAtPrice,
        };
    }

    return {
        isDiscounted: false,
        price: price,
    };
};

export { getProductPriceInfo };
