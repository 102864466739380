import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    margin: 0;
  }
  main {
    margin-bottom: 40px;
  }
  /* Prevent blue outline when <ImageSlider> image is clicked/tapped */
  .image-gallery-slide {
    outline: none;
  }
`;

export default GlobalStyle;
