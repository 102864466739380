const PELA_PRODUCT_METAFIELD_NAMESPACE = 'demac';
const SHOPIFY_ID_DECODED_URI_REGEX = new RegExp('^gid:\/\/shopify\/Product\/([0-9]+)$');
const SHOPIFY_VARIANT_ID_DECODED_URI_REGEX = new RegExp('^gid:\/\/shopify\/ProductVariant\/([0-9]+)$');
const SHOPIFY_LINEITEM_ID_DECODED_URI_REGEX = new RegExp('^gid:\/\/shopify\/CheckoutLineItem\/([0-9]+)\?(.)+$');

const getMetaFieldValue = (product, key, namespace = PELA_PRODUCT_METAFIELD_NAMESPACE) => {
    const { metafields } = product;
    if (metafields) {
      const metafield = metafields.find(x => x.namespace === namespace && x.key === key);
      if (metafield) {
        return metafield.value;
      }
    }
    console.warn(`The '${namespace}.${key}' metafield is missing for ${product.handle}`);
};

exports.getColour = (product) => {
  return getMetaFieldValue(product, 'colour');
};

exports.extractIntegerIdFromShopifyId = (productId) => { /* returns number (int) */
    // The product.shopifyId property contains a base-64 encoded URI that contains the actual Shopify integer id for the product
    const decoded = Buffer.from(productId, 'base64').toString()
    if (decoded) {
      const matches = decoded.match(SHOPIFY_ID_DECODED_URI_REGEX);
      if (matches && matches.length > 1) {
        return parseInt(matches[1]);
      } else {
        throw new Error(`Decoded shopifyId does not match expected URI regex for product ${productId}: ${decoded}`);
      }
    } else {
      throw new Error(`Decoded shopifyId is null/undefined for ${productId}: ${decoded}`);
    }
};

exports.extractIntegerIdFromShopifyVariantId = (productVariantId) => { /* returns number (int) */
    // The product.shopifyId property contains a base-64 encoded URI that contains the actual Shopify integer id for the product
    const decoded = Buffer.from(productVariantId, 'base64').toString()
    if (decoded) {
      const matches = decoded.match(SHOPIFY_VARIANT_ID_DECODED_URI_REGEX);
      if (matches && matches.length > 1) {
        return parseInt(matches[1]);
      } else {
        throw new Error(`Decoded variant id does not match expected URI regex for product : ${decoded}`);
      }
    } else {
      throw new Error(`Decoded variant id is null/undefined for : ${decoded}`);
    }
};

exports.extractIntegerIdFromShopifyLineItemId = (productLineItemId) => { /* returns number (int) */
  // The product.shopifyId property contains a base-64 encoded URI that contains the actual Shopify integer id for the product
  const decoded = Buffer.from(productLineItemId, 'base64').toString()
  if (decoded) {
    const matches = decoded.match(SHOPIFY_LINEITEM_ID_DECODED_URI_REGEX);
    if (matches && matches.length > 1) {
      return parseInt(matches[1]);
    } else {
      throw new Error(`Decoded line item id does not match expected URI regex for product : ${decoded}`);
    }
  } else {
    throw new Error(`Decoded line item id is null/undefined for : ${decoded}`);
  }
};
