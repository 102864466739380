import { Builder, builder } from '@builder.io/react';
import ImageBand from './imageBand';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(ImageBand, {
    name: "Icon Banner",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "title",
            type: "string",
        },
        {
            name: "description",
            type: "string",
        },
        {
            name: "textColorHex",
            type: "color",
            helperText: "Defaults to black",
        },
        {
            name: "backgroundColorHex",
            type: "color",
            helperText: "Defaults to light grey",
        },
        {
            name: "imageListItems",
            type: "list",
            required: true,
            defaultValue: [
                { title: "Image 1" },
            ],
            subFields: [
                {
                    name: "linkUrl",
                    type: "string",
                },
                {
                    name: "image",
                    type: "file",
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                    required: true,
                }
            ]
        },
    ]
})
