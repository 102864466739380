const hexByColour = new Map<string, string>();
hexByColour.set('black', '#000000');
hexByColour.set('green', '#416365');
hexByColour.set('lavender', '#9ea3e3');
hexByColour.set('beetroot', '#661c1a');
hexByColour.set('blue', '#3a376d');
hexByColour.set('ocean_turquoise', '#a5e5d5');
hexByColour.set('rose_quartz', '#efc9e0');
hexByColour.set('oceana_blue', '#167cdf');
hexByColour.set('skyblue', '#a3d3ee');
hexByColour.set('sunshine_yellow', '#f3f36d');
hexByColour.set('white', '#fafafa');
hexByColour.set('red', '#aa2c37');
hexByColour.set('red_canada', '#c80707');
hexByColour.set('shark_skin', '#767b7f');
hexByColour.set('light_brown', '#d6b691');
hexByColour.set('oceana_blue_wavemaker', '#177fdd');
hexByColour.set('honey', '#deaa15');
hexByColour.set('honey_bee', '#f2b811');
hexByColour.set('coral', '#ff6d6d');
hexByColour.set('seashell', '#fceec2');
hexByColour.set('seashell_flower', '#f9ecd0');
hexByColour.set('tidal', '#308ba1');
hexByColour.set('moss', '#427141');
hexByColour.set('moss_flower', '#859b84');
hexByColour.set('brown', '#5e3434');
hexByColour.set('clear_shiny', '#fef8f8');
hexByColour.set('gray', '#d0d0d0');
hexByColour.set('matt_black', '#000000');
hexByColour.set('clear_matt', '#fefbfb');
hexByColour.set('pumpkin_spice', '#f6a538');
hexByColour.set('clay', '#969090');
hexByColour.set('wave', '#706f7d');
hexByColour.set('orange', '#ed9111');
hexByColour.set('cassis', '#B56E82');
hexByColour.set('cantaloupe', '#f2965a');
hexByColour.set('puristblue', '#20ade9');
hexByColour.set('neomint', '#91f3a5');
hexByColour.set('yellow', '#f4c712');
hexByColour.set('pebble', '#DAE0BE');
hexByColour.set('blush', '#F9F3F3');
hexByColour.set('pink', '#F1659D');
hexByColour.set('apple_blue', '#0D75B8');
hexByColour.set('apple_green', '#A1BBA0');
hexByColour.set('spa_blue', '#7DB4DE');
hexByColour.set('chestnut', '#A1754C');
hexByColour.set('sage_green', '#B1C99F');
hexByColour.set('london_fog', '#C5B9AC');
hexByColour.set('terracotta', '#C26E60');
hexByColour.set('wisteria_purple', '#A05EB5');
hexByColour.set('tuscan_yellow', '#DCB968');
hexByColour.set('stormy_blue', '#1F5F77');

export default function colourToHex(colour: string) {
    const hex = hexByColour.get(colour);
    if (hex) {
        return hex;
    }
    throw new Error(`No hex colour value found for ${colour}`);
};
