import React from 'react';
import { Helmet } from 'react-helmet';
import theme from '../theme';
import GlobalStyle from '../globalStyle';
import Footer from '../components/footer';
import Header from '../components/header';
import { makeStyles, ThemeProvider, Toolbar } from '@material-ui/core';
import classes from '*.module.css';

const useStyles = makeStyles(theme => ({
    background: (props: RootPageProps) => ({
        backgroundColor: props.backgroundColour ? props.backgroundColour : "transparent",
    }),
    toolbar: {
        minHeight: "70px",
    },
}));

interface RootPageProps {
    seoTitle?: string;
    seoDescription?: string;
    header: string;
    headerColour?: string;
    ctaColour?: "primary" | "secondary" | "default";
    ctaText?: string;
    ctaUrl?: string;
    disableHeader: boolean;
    disableFooter: boolean;
    caption?: string;
    backgroundColour?: string;
    children: React.ReactNode;
}

export default function RootPage(props: RootPageProps) {
    const classes = useStyles(props);
    return (
        <div className={classes.background}>
            <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Helmet>
                    <meta charSet="UTF-8" />
                    <meta name="theme-color" content="#f8f8f8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, user-scalable=no"
                    />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
                    <title>{props.seoTitle || "Get Pela Case"}</title>
                    <meta name="description" content={props.seoDescription || "Pela phone cases"} />
                </Helmet>
                {/* <link href="https://fonts.googleapis.com/css2?family=Lora&family=Rubik:wght@400;500&display=swap" rel="stylesheet" /> */}
                <style>
                    {`
                        /* latin-ext */
                        @font-face {
                        font-family: 'Lora';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787weuxJPkqt8ndeYxZ2JTg.woff) format('woff');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                        }
                        /* latin */
                        @font-face {
                        font-family: 'Lora';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url(https://fonts.gstatic.com/s/lora/v16/0QI6MX1D_JOuGQbT0gvTJPa787weuxJBkqt8ndeYxZ0.woff) format('woff');
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                        }
                        /* latin-ext */
                        @font-face {
                        font-family: 'Rubik';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                        }
                        /* latin */
                        @font-face {
                        font-family: 'Rubik';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: local('Rubik'), local('Rubik-Regular'), url(https://fonts.gstatic.com/s/rubik/v9/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                        }
                        /* latin-ext */
                        @font-face {
                        font-family: 'Rubik';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7EyjmmT8WD07oB-98o.woff2) format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
                        }
                        /* latin */
                        @font-face {
                        font-family: 'Rubik';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: local('Rubik Medium'), local('Rubik-Medium'), url(https://fonts.gstatic.com/s/rubik/v9/iJWHBXyIfDnIV7Eyjmmd8WD07oB-.woff2) format('woff2');
                        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                        }
                    `}
                </style>
                { !props.disableHeader &&
                    <>
                        <Header
                            headerColour={props.headerColour}
                            ctaColour={props.ctaColour}
                            ctaText={props.ctaText}
                            ctaUrl={props.ctaUrl}
                            caption={props.caption}
                        />
                        <Toolbar className={classes.toolbar} />
                    </>
                }
                <main>{props.children}</main>
                { !props.disableFooter && <Footer /> }
            </ThemeProvider>
        </div>
    );
}
