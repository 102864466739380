import React from "react"
import ButtonLink from "../components/buttonLink/buttonLink"
import { createGlobalStyle } from "styled-components";
import Grid from "@material-ui/core/Grid/Grid";
import { Typography, makeStyles } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  div#root {
    height: 100%;
  }
  body {
    font-family: "Rubik", sans-serif;
    font-size: 1rem;
    margin: 0;
    height: 100%;
  }
`;

const useStyles = makeStyles(theme => ({
  main: {
    height: '100%',
    width: '100%',
    backgroundColor: '#A9D71B', // '#eeffee',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: 'url(https://www.datocms-assets.com/23505/1596739644-pela-leaf-transparent-512x512.png)',
  },
  container: {
    textAlign: 'center',
    '& h1': {
      color: '#ffffff',
      marginTop: '0',
      textShadow: '1px 1px #444',
    },
    '& p': {
      marginBottom : '2rem',
      textShadow: '1px 1px #444',
      color: '#ffffff',
    },
  },
}));

export default function NotFoundPage() {
  const classes = useStyles();
  return (
    <>
      <GlobalStyle />
      <main className={classes.main}>
        <Grid container justify="center" alignItems="center" style={{ height: '100%' }}>
          <Grid item xs>
            <div className={classes.container}>
              <Typography variant="h1">Page Not Found</Typography>
              <Typography variant="body1">The page you requested does not exist</Typography>
              {/* <ButtonLink text="Continue Shopping" url="/" /> */}
            </div>
          </Grid>
        </Grid>
      </main>
    </>
  );
}
