import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress, Typography } from "@material-ui/core";
import { ProductThumbnailProps } from "./productThumbnailProps";
import { getProductPriceInfo } from "../../utilities/productPriceCalculator";
import ProductPrice from "./productPrice/productPrice";
import ProductSwatch from "./productSwatch";
import AddToCartButton from "../buttons/addToCartButton";
import BuyNowButton from "../buttons/buyNowButton";
import ProductBadge from "./productBadge";

const ADD_TO_CART_BUTTON_HEIGHT = '40px';

const useStyles = makeStyles(theme => ({
    productThumbnail: {
        '&:hover a': {
            visibility: 'visible',
        },
        '&:hover $outOfStock': {
            visibility: 'visible',
        },
        '&:hover button': {
            visibility: 'visible',
        },
    },
    imageLinkContainer: {
        position: 'relative',
        display: 'inline-block',
        overflow: 'hidden',
        color: '#000',
        textDecoration: 'none',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            '&:hover $disappearOnHover': {
                display: 'none',
            },
            '&:hover $appearOnHover': {
                display: 'block',
            },
        }
    },
    img: {
        width: "100%",
    },
    imageContainer: {
        width: '100%',
        textAlign: 'center',
        margin: '0 auto',
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            minHeight: '300px',
        }
    },
    noImage: {
        width: '80%',
        height: '300px',
        textAlign: 'center',
        background: theme.palette.grey[100],
        fontSize: '0.8rem',
        color: '#ddd',
        lineHeight: '300px',
        overflow: 'hidden',
        margin: '0 auto',
    },
    disappearOnHover: {
    },
    appearOnHover: {
        display: 'none',
    },
    title: {
        margin: '10px 0',
        fontWeight: theme.typography.fontWeightMedium,
    },
    topLeftBadge: {
        position: 'absolute',
        left: 10,
        top: 10,
        zIndex: 2,
    },
    outOfStock: {
        color: theme.palette.error.main,
        width: '100%',
        margin: '10px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        height: ADD_TO_CART_BUTTON_HEIGHT,
        lineHeight: ADD_TO_CART_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    hoverAddToCart: {
        [theme.breakpoints.up('sm')]: {
            visibility: 'hidden',
        }
    },
    addToCartButtonLink: {
        '& button': {
            margin: '10px 0',
            fontSize: '0.8rem',
            height: ADD_TO_CART_BUTTON_HEIGHT,
            overflow: 'hidden',
        }
    },
    hoverAddToCartLink: {
        '& button': {
            [theme.breakpoints.up('sm')]: {
                visibility: 'hidden',
            }
        }
    },
    description: {
        marginBottom: '5px',
    },
    soldOut: {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightBold,
        position: 'absolute',
        top: '40%',
        width: '100%',
        fontSize: '2rem',
    },
    claimedText: {
        color: "#ff0000",
    },
    root: {
        height: "20px",
        "&.MuiLinearProgress-colorPrimary:not(.MuiLinearProgress-buffer)": {
          backgroundColor: "#ffcccc"
        },
        "& .MuiLinearProgress-colorPrimary": {
          backgroundColor: "#ffcccc"
        },
        "& .MuiLinearProgress-barColorPrimary": {
          backgroundColor: "#ff0000"
        },
        "& .MuiLinearProgress-dashedColorPrimary": {
          backgroundImage:
            "radial-gradient(#ffcccc 0%, #ffcccc 16%, transparent 42%)"
        }
    },
}));

export default function ProductThumbnail(props: ProductThumbnailProps) {
    const classes = useStyles();
    const {
        products,
        disableLink,
        title,
        description,
        discountPercent,
        alwaysShowButton,
        useViewProductButton,
        urlParameterString,
        noHoverImage,
        toggleType,
        useBuyNowButton,
        freeProduct,
        buyButtonLabel,
        showAsSoldOut,
        showAsLowStock,
        unitsClaimed,
        unitsAvailable,
        allClaimedTag,
        index
    } = props;
    const [state, setState] = React.useState({
        selectedProductIndex: 0,
    });

    const setSelectedProductIndex = (selectedProductIndex: number) => {
        setState((prevState) => {
            return { ...prevState, selectedProductIndex };
        });
    };

    if (products.length) {
        const selectedProduct = products[state.selectedProductIndex];
        const selectedProductPriceInfo = getProductPriceInfo(selectedProduct);
        const isOutOfStock = !selectedProduct.availableForSale || showAsSoldOut;
        const isPreOrder = !!(selectedProduct.tags && selectedProduct.tags.some(tag => tag === 'Preorder'));
        const imageCount = selectedProduct.images ? selectedProduct.images.length : 0;
        const firstImage = imageCount > 0 && selectedProduct.images && selectedProduct.images[0];
        const lastImage = imageCount > 1 && selectedProduct.images && selectedProduct.images[imageCount-1];
        const allClaimed = unitsClaimed && unitsAvailable && unitsClaimed >= unitsAvailable;

        const linkContent = (
            <>
                <div className={classes.topLeftBadge}>
                  {
                      index ?
                        <ProductBadge>{index}</ProductBadge>
                      :
                      <></>
                  }
                  {
                      isOutOfStock ?
                      <ProductBadge color="red">SOLD OUT</ProductBadge>
                      :
                      <>
                          {
                              allClaimed || (allClaimedTag && selectedProduct.tags?.includes(allClaimedTag)) ?
                              <ProductBadge color="red">CLOSED</ProductBadge>
                              :
                              <>
                                  {
                                      (selectedProductPriceInfo.isDiscounted || (unitsClaimed && unitsAvailable && unitsClaimed < unitsAvailable && discountPercent && discountPercent > 0)) ?
                                      <ProductBadge color="green">Sale</ProductBadge> : null
                                  }
                              </>
                          }
                      </>
                  }
                </div>
                <div className={classes.imageContainer}>
                    {
                        firstImage
                        ?
                            <span className={lastImage && !noHoverImage ? classes.disappearOnHover : undefined}>
                                {/* See https://github.com/gatsbyjs/gatsby/pull/17006 for why presentationWidth is used here */}
                                <img className={classes.img} src={firstImage.src} alt={firstImage.altText} />
                                {showAsSoldOut && <Typography variant="body1" className={classes.soldOut}>SOLD OUT</Typography>}
                                {showAsLowStock && <Typography variant="body1" className={classes.soldOut}>LOW STOCK</Typography>}
                            </span>
                        :
                            <div className={classes.noImage}>No Image</div>
                    }
                    {
                        firstImage && lastImage && !noHoverImage &&
                        <span className={classes.appearOnHover}>
                            <img className={classes.img} src={lastImage.src} alt={lastImage.altText} />
                            {showAsSoldOut && <Typography variant="body1" className={classes.soldOut}>SOLD OUT</Typography>}
                            {showAsLowStock && <Typography variant="body1" className={classes.soldOut}>LOW STOCK</Typography>}
                        </span>
                    }
                </div>
                {
                    (unitsClaimed && unitsAvailable) &&
                    <div>
                        <Typography align="right" className={classes.claimedText} variant="subtitle2">{allClaimedTag && selectedProduct.tags?.includes(allClaimedTag) ? `${unitsAvailable}/${unitsAvailable} Claimed` : `${unitsClaimed}/${unitsAvailable} Claimed`}</Typography>
                        <LinearProgress className={classes.root} variant="determinate" value={allClaimedTag && selectedProduct.tags?.includes(allClaimedTag) ? 100 : (unitsClaimed / unitsAvailable) * 100} />
                    </div>
                }
                <div>
                    <Typography variant="body1" className={classes.title}>{title ? title : selectedProduct.title}</Typography>
                    { description && <Typography variant="body2" className={classes.description}>{description}</Typography> }
                    {
                        <div>
                            <ProductPrice showAsFree={!!freeProduct} discountPercent={discountPercent} productPriceInfo={selectedProductPriceInfo} displayMode="small" productHandle={selectedProduct.handle} />
                        </div>
                    }
                </div>
            </>
        );


        return (
            <div className={classes.productThumbnail}>
                {
                    <div className={classes.imageLinkContainer}>
                        {linkContent}
                    </div>
                }
                <div>
                    {
                        products.length > 1 && // Only show swatches when there are multiple products
                        <>
                            {
                                products.map((product, productIndex) => {
                                    return (
                                        <ProductSwatch
                                            key={productIndex}
                                            product={product}
                                            selected={state.selectedProductIndex === productIndex}
                                            onClick={() => setSelectedProductIndex(productIndex)}
                                        />
                                    );
                                })
                            }
                        </>
                    }
                </div>
                {/* TODO: i18n */}
                {
                    (isOutOfStock || allClaimed || (allClaimedTag && selectedProduct.tags?.includes(allClaimedTag)))
                    ?
                        // TODO: i18n
                        <Typography variant="caption" className={alwaysShowButton ? classes.outOfStock : `${classes.outOfStock} ${classes.hoverAddToCart}`}>
                            Out of Stock
                        </Typography>
                    :
                        useBuyNowButton
                        ?
                            <BuyNowButton
                                showOnHover={!alwaysShowButton}
                                isPreOrder={isPreOrder}
                                product={selectedProduct}
                                isOutOfStock={isOutOfStock}
                                useViewProductButton={useViewProductButton}
                                textSmall
                                fullWidth
                                quantity={1}
                                urlParameterString={urlParameterString}
                                buttonLabel={buyButtonLabel}
                                freeProduct={freeProduct}
                            />
                        :
                            <AddToCartButton
                                showOnHover={!alwaysShowButton}
                                isPreOrder={isPreOrder}
                                product={selectedProduct}
                                isOutOfStock={isOutOfStock}
                                useViewProductButton={useViewProductButton}
                                textSmall
                                fullWidth
                                quantity={1}
                                urlParameterString={urlParameterString}
                                freeProduct={freeProduct}
                            />
                }
            </div>
        );
    } else {
        return null;
    }
}
