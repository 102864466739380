import axios from 'axios';
import QueryStringBuilder from '../utilities/queryStringBuilder';

class KlaviyoClient {

    constructor() {
    }

    async subscribeEmail(emailAddress: string, listId: string) {
        try {
            const queryStringBuilder = new QueryStringBuilder();
            queryStringBuilder
                .add('email', emailAddress)
                .add('g', listId)
                .add('$timezone_offset', ((new Date()).getTimezoneOffset() / 60 * -1.0).toString())
                .add('$source', '$embed')
                .add('$fields', '$source');
            const response = await axios({
                method: 'post',
                url: `https://manage.kmail-lists.com/ajax/subscriptions/subscribe`,
                data: queryStringBuilder.build(),
                headers: {
                    'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
                },
            });

            if (response.status !== 200) {
                throw new Error(`Error subscribing ${emailAddress} to Klaviyo email list ${listId}: ${response.status} ${response.statusText}`);
            }

            if (!response.data.success) {
                throw new Error(`Non-success from Klaviyo: ${JSON.stringify(response.data)}`);
            }
        } catch (error) {
            console.error(error);
            throw new Error('Error subscribing to email list');
        }
    }
}

export default KlaviyoClient;
