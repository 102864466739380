import React from "react";
import { HtmlBlockProps } from "./htmlBlockProps";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    htmlBlock: {
        '& h1': theme.typography.h1,
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
        '& p': theme.typography.body1,
        '& li': theme.typography.body1,

        '& a': {
            color: '#000',
            textDecoration: 'underline',
            textDecorationColor: theme.palette.primary.main,
        },

        '& a:hover': {
            color: theme.palette.primary.main,
        },
    },
}));

export default function HtmlBlock(props: HtmlBlockProps) {
    const { html, className } = props;

    const classes = useStyles();
    return (
        <div className={`${classes.htmlBlock} ${className}`} dangerouslySetInnerHTML={{__html: html}}></div>
    );
};
