import React from "react";
import { BuilderLimitedInventoryCollectionProps } from "./builderLimitedInventoryCollectionProps";
import LimitedInventoryCollection from "./limitedInventoryCollection";

export default function BuilderLimitedInventoryCollection(props: BuilderLimitedInventoryCollectionProps) {
    const { collectionHandle, discountPercent, saleStartTime, allClaimedTag, saleFinishTime, unitsAvailable, title, descriptionHtml, alwaysShowButton, useViewProductButton } = props;

    if (collectionHandle && saleStartTime && saleFinishTime && unitsAvailable) {
        return (
            <>
                <LimitedInventoryCollection
                    handle={collectionHandle}
                    saleStartTime={saleStartTime}
                    saleFinishTime={saleFinishTime}
                    unitsAvailable={unitsAvailable}
                    discountPercent={discountPercent}
                    useViewProductButton={useViewProductButton}
                    alwaysShowButton={alwaysShowButton}
                    descriptionHtml={descriptionHtml}
                    allClaimedTag={allClaimedTag}
                    title={title}
                />
            </>
        );
    } else {
        return null;
    }
}
