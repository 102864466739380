import React from "react";
import { FixedWidthCenteredContentProps } from "./fixedWidthCenteredContentProps";
import { Container } from "@material-ui/core";

export default function FixedWidthCenteredContent(props: FixedWidthCenteredContentProps) {
    return (
        <Container fixed>
            <>{props.children}</>
        </Container>
    );
}
