import React from "react";
import { AppBar, Button, Grid, makeStyles, Toolbar, Typography } from "@material-ui/core";
import pelaLogo from "../static/pela-logo-grey.png";
import SmartLink from "./links/smartLink";
import { ArrowForward } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    navColour: (props: HeaderProps) => ({
        backgroundColor: props.headerColour || "#ffffff",
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
    }),
    buttonLink: {
        textTransform: "none",
        borderRadius: "24px",
        overflow: 'hidden',
        padding: '8px 24px',
    },
    fullNavLogoContainer: {
        marginLeft: '10px',
    },
    fullNavLogoLink: {
        verticalAlign: 'middle',
    },
    onlyLogoContainer: {
        textAlign: 'center',
    },
    button: {
        textAlign: "right",
    },
    toolbar: {
        minHeight: "70px",
    },
    logo: {
        maxWidth: '80px',
    },
    caption: {
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        '& p': {
            margin: '0',
        }
    }
}));

interface HeaderProps {
    headerColour?: string;
    ctaUrl?: string;
    ctaText?: string;
    ctaColour?: "primary" | "secondary" | "default";
    caption?: string;
}

export default function Header(props: HeaderProps) {
    const classes = useStyles(props);
    return (
        <AppBar elevation={0} position="fixed" className={classes.navColour}>
            <Toolbar className={classes.toolbar}>
                <Grid container alignItems="center">
                    {/* Compact menu icon and Pela logo */}
                    <Grid item xs={!props.ctaUrl && !props.caption ? 12 : 2} md={!props.ctaUrl && !props.caption ? 12 : 3}>
                        <div className={!props.ctaUrl && !props.caption ? classes.onlyLogoContainer : classes.fullNavLogoContainer}>
                            <SmartLink url={process.env.REACT_APP_PELA_STORE_URL || ""} className={classes.fullNavLogoLink}>
                                <img src={pelaLogo} alt="Pela Case" className={classes.logo} />
                            </SmartLink>
                        </div>
                    </Grid>
                    {
                        props.caption &&
                        <Grid md={6} className={classes.caption}>
                            <Typography variant="body1">{props.caption}</Typography>
                        </Grid>
                    }
                    {(props.ctaUrl && props.ctaText) &&
                        <Grid item xs={10} md={props.caption ? 3 : 9} className={classes.button}>
                            <Button
                                href={props.ctaUrl}
                                variant="contained"
                                color={props.ctaColour ? props.ctaColour : "primary"}
                                className={classes.buttonLink}
                                endIcon={<ArrowForward />}
                            >
                                {props.ctaText}
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Toolbar>
        </AppBar>
    );
}
