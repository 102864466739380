import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#8EBD04',
            main: '#8EBD04',
            dark: '#7FAA03',
            contrastText: '#ffffff',
        },
        secondary: {
            light: '#012D76',
            main: '#012D76',
            dark: '#012D76',
            contrastText: '#ffffff',
        },
        error: {
            light: '#D40031',
            main: '#D40031',
            dark: '#D40031',
            contrastText: '#ffffff',
        },
        warning: {
            light: '#D6B691',
            main: '#D6B691',
            dark: '#D6B691',
            contrastText: '#000000',
        },
        info: {
            light: '#012D76',
            main: '#012D76',
            dark: '#012D76',
            contrastText: '#ffffff',
        },
        success: {
            light: '#8EBD04',
            main: '#8EBD04',
            dark: '#8EBD04',
            contrastText: '#ffffff',
        },
        grey: {
            100: '#F4F4F2',
            200: '#E2E2E2',
            300: '#D0D0D0',
            900: '#4F5057',
        },
        text: {
            primary: '#000000',
            secondary: '#4F5057',
            disabled: '#D0D0D0',
            hint: '#D0D0D0',
        }
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: '"Rubik", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },
    shape: {
        borderRadius: 0,
    },
    // https://material-ui.com/customization/default-theme/?expand-path=$.zIndex
    zIndex: {
        modal: 1000000, // Place modals (eg. mini-cart drawer) above Gorgias chat button
        snackbar: 1000100,
        tooltip: 1000200,
    },
    // Prevent iOS zoom on input focus: https://www.warrenchandler.com/2019/04/02/stop-iphones-from-zooming-in-on-form-fields/
    overrides: {
        MuiInputBase: {
            input: {
                fontSize: '16px',
            }
        },
    },
});

// Typography set up below theme declaration to allow for theme.breakpoints usage
theme.typography.h1 = {
    fontFamily: '"Lora", serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    margin: '1em 0 .67em',
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.875rem',
    },
};

theme.typography.h2 = {
    margin: '.75em 0',
    fontFamily: '"Lora", serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '2rem',
    lineHeight: 1.22,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.625rem',
    },
};

theme.typography.h3 = {
    margin: '.83em 0',
    fontFamily: '"Lora", serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.75rem',
    lineHeight: 1.2,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
        lineHeight: 1.3,
    },
};

theme.typography.h4 = {
    margin: '1.12em 0',
    fontFamily: '"Lora", serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.375rem',
    lineHeight: 1.36,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        lineHeight: 1.27,
    },
};

theme.typography.h5 = {
    margin: '1.12em 0',
    fontFamily: '"Lora", serif',
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 1.45,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
    },
};

theme.typography.h6 = {
    margin: '1.12em 0',
    fontFamily: '"Lora", serif',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1.125rem',
    lineHeight: 1.39,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: 1.56,
    },
};

theme.typography.body1 = {
    margin: '0 0 1em',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '1rem',
    lineHeight: 1.56,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
        lineHeight: 1.43,
        marginBottom: '0.875rem',
    },
};

theme.typography.body2 = {
    margin: '0 0 0.875em',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '0.875rem',
    lineHeight: 1.56,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        lineHeight: 1.43,
        marginBottom: '0.75rem',
    },
};

theme.typography.caption = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    color: theme.palette.text.secondary,
    display: 'block',
};

theme.typography.overline = {
    marginBottom: '0.7em',
    display: 'block',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '0.75rem',
    lineHeight: 1.833,
    letterSpacing: '0.3125rem',
    textTransform: 'uppercase',
};

theme.typography.button = {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: '1rem',
    lineHeight: 1.75,
    textTransform: 'uppercase',
};

theme.props = {
    MuiButton: {
      disableElevation: true,
    },
};

export default theme;
