class QueryStringBuilder {
    buffer: string[];

    constructor() {
        this.buffer = [];
    }

    add(key: string, value: string) {
        this.buffer.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
        return this; // fluent
    }

    build() {
        return this.buffer.join('&');
    }
}

export default QueryStringBuilder;
