import React from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import './App.css';
import LandingPage from './pages/landingPage';

function App() {
  return (
    <Router>
      <Switch>
        <Route render={({ location }) => <LandingPage key={location.key} />} />
      </Switch>
    </Router>
  );
}

export default App;
