import { Builder, builder } from '@builder.io/react';
import TwoColumnShowCase from './twoColumnShowCase';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(TwoColumnShowCase, {
    name: "Two Column Image and Text",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "preTitle",
            type: "string",
        },
        {
            name: "title",
            type: "string",
        },
        {
            name: "description",
            type: "richText",
        },
        {
            name: "linkText",
            type: "string",
        },
        {
            name: "linkUrl",
            type: "string",
        },
        {
            name: "image",
            type: "file",
            allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg', 'gif'],
        },
        {
            name: "videoUrl",
            type: "string",
        },
        {
            name: "textRight",
            type: "boolean",
            helperText: 'Text is on the left by default, set this to have the text appear on the right'
        },
    ]
})
