import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ReviewsSectionProps } from "./reviewsSectionProps";
import { Grid, Typography } from "@material-ui/core";
import { ScoreDisplay } from "./scoreDisplay";
import ButtonLink from "../buttonLink/buttonLink";
import FixedWidthCenteredContent from "../mainContent/fixedWidthCenteredContent";

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 'auto'),
        '&>div': {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2, 0),
            },
        },
    },
    title: {
        margin: '0 auto',
        textAlign: 'center',
    },
    reviewTitle: {
        margin: theme.spacing(1, 0, 2),
    },
    buttonContainer: {
        textAlign: 'center',
    },
    image: {
        width: '100%',
        marginBottom: theme.spacing(1),
    }
}));

export default function ReviewsSection(props: ReviewsSectionProps) {
    const classes = useStyles();
    const { title, reviews, buttonText, buttonLink } = props;
    if (!reviews || reviews.length === 0) {
        return null;
    }

    return (
        <FixedWidthCenteredContent>
            <Grid container className={classes.container}>
                {
                    title &&
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.title}>{title}</Typography>
                    </Grid>
                }
                {
                    reviews.map((review, index) => {
                        return (
                            <Grid item xs={12} md={4} key={index}>
                                {review.image && <img src={review.image} className={classes.image} />}
                                <Typography variant="caption">{review.name}</Typography>
                                <ScoreDisplay outOf={5} score={5} size="small" />
                                {review.title && <Typography variant="h5" className={classes.reviewTitle}>{review.title}</Typography>}
                                <Typography variant="body2">{review.review}</Typography>
                            </Grid>
                        );
                    })
                }
                {
                    buttonText && buttonLink &&
                    <Grid item xs={12} className={classes.buttonContainer}>
                        <ButtonLink url={buttonLink} text={buttonText} />
                    </Grid>
                }
            </Grid>
        </FixedWidthCenteredContent>
    );
}
