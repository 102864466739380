import { makeStyles } from "@material-ui/core";
import React from "react";
import { BuilderHyperlinkProps } from "./builderHyperlinkProps";

const useStyles = makeStyles(theme => ({
    anchor: {
        display: "block",
        position: "relative",
        top: "-80px",
        visibility: "hidden",
    },
}));

export default function BuilderHyperlink(props: BuilderHyperlinkProps) {
    const classes = useStyles();
    const { linkId } = props;
    return (
        <a className={classes.anchor} id={linkId} />
    );
}
