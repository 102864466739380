import { Builder, builder } from '@builder.io/react';
import ImageGrid from './imageGrid';
builder.init(process.env.REACT_APP_BUILDER_API_KEY || "");
Builder.isStatic = true;

Builder.registerComponent(ImageGrid, {
    name: "Image Grid",
    image: "https://cdn.builder.io/api/v1/image/assets%2F9d6dbdeca56c4ad0989f4275a7f3d621%2F8c18218394ca460fa6a90cbe4aaf75bd",
    inputs: [
        {
            name: "pretitle",
            type: "string",
        },
        {
            name: "title",
            type: "string",
        },
        {
            name: "description",
            type: "richText",
        },
        {
            name: "titleBesideIcon",
            type: "boolean",
            helperText: "Should the title show to the left of the icon or image? Defaults to below.",
        },
        {
            name: "linkText",
            type: "string",
        },
        {
            name: "linkUrl",
            type: "string",
        },
        {
            name: "numberOfCol",
            friendlyName: "Number of image columns",
            type: "number",
            helperText: "How many images do you want? Must be between 2 and 4. Defaults to 3 if left empty.",
        },
        {
            name: "imageListItems",
            type: "list",
            required: true,
            subFields: [
                {
                    name: "pretitle",
                    type: "string",
                },
                {
                    name: "title",
                    type: "string",
                },
                {
                    name: "descriptionHtml",
                    type: "richText"
                },
                {
                    name: "linkText",
                    type: "string",
                },
                {
                    name: "linkUrl",
                    type: "string",
                },
                {
                    name: "image",
                    type: "file",
                    allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
                    required: true,
                }
            ]
        },
    ]
})
