import React from "react";
import { AddToCartButtonProps } from "./addToCartButtonProps";
import { Button, makeStyles } from "@material-ui/core";
import { getProductRelativeUrl } from '../../utilities/linkHelper';
import { v4 as uuidv4 } from 'uuid';
import GlobalContext from "../../context/globalContext";
import { builder } from '@builder.io/react';

const ADD_TO_CART_BUTTON_HEIGHT = '40px';

const useStyles = makeStyles(theme => ({
    outOfStock: {
        color: theme.palette.error.main,
        width: '100%',
        margin: '10px 0',
        textAlign: 'center',
        textTransform: 'uppercase',
        height: ADD_TO_CART_BUTTON_HEIGHT,
        lineHeight: ADD_TO_CART_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    addToCartButton: {
        width: '100%',
        margin: '10px 0',
        height: ADD_TO_CART_BUTTON_HEIGHT,
        lineHeight: ADD_TO_CART_BUTTON_HEIGHT,
        overflow: 'hidden',
    },
    showOnHover: {
        [theme.breakpoints.up('sm')]: {
            visibility: 'hidden',
        }
    },
    buttonSecondary: {
        border: '1px solid black',
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        }
    },
    textSmall: {
        fontSize: '0.8rem',
    }
}));

export default function AddToCartButton(props: AddToCartButtonProps) {
    const classes = useStyles();
    const { showOnHover,
            fullWidth,
            primary,
            isPreOrder,
            product,
            textSmall,
            urlParameterString,
            useViewProductButton,
            landingPageOnATC,
            quantity,
            isOutOfStock } = props;
    let buttonClass = showOnHover ?  `${classes.addToCartButton} ${classes.showOnHover}` : classes.addToCartButton;
    buttonClass = primary ? buttonClass : `${buttonClass} ${classes.buttonSecondary}`;
    buttonClass = textSmall ? `${buttonClass} ${classes.textSmall}` : buttonClass;
    const pageSlug = landingPageOnATC || "/cart";

    return (
        <GlobalContext.Consumer>
            {context => {
                if (!context || !context.storeUrl) {
                    return null;
                }
                const addToCartButtonText = isPreOrder ? 'Preorder Now' : 'Add To Cart'; // TODO: 118n
                const addToCartQueryParam = "landerAddToCart=" + new URLSearchParams(JSON.stringify(product)).toString();
                const addToCartQueryId = "landerAddToCartId=" + uuidv4();
                const addToCartLink = `${context.storeUrl}${pageSlug}?${addToCartQueryParam}&${addToCartQueryId}${urlParameterString ? `&${urlParameterString}` : ""}`;

                const viewProductText = "View Product";
                const viewProductLink = `${context.storeUrl}${getProductRelativeUrl(product.handle)}${urlParameterString ? `?${urlParameterString}` : ""}`;

                return (
                    <Button
                        href={useViewProductButton ? viewProductLink : addToCartLink}
                        className={buttonClass}
                        aria-label={addToCartButtonText}
                        fullWidth={fullWidth}
                        color={primary ? "primary" : "default"}
                        variant={primary ? "contained" : "text"}
                    >
                        {useViewProductButton ? viewProductText : addToCartButtonText}
                    </Button>
                );
            }}
        </GlobalContext.Consumer>
    )
};
