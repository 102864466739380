import { HeroProps } from "./heroProps";
import BannerImage from "../image/bannerImage";
import BannerImageOverlay from "../imageOverlay/bannerImageOverlay";
import GlobalContext from "../../context/globalContext";

export default function Hero(props: HeroProps) {
    const { layout,
        backgroundColor,
        image,
        html,
        ctaButtonLink,
        ctaButtonSlug,
        ctaButtonText,
        buttonTextBlack,
        buttonBackgroundColor,
        postButtonHtml } = props;
    return (
        <GlobalContext.Consumer>
            {context => {
                if (!context || !context.storeUrl) {
                    return null;
                }
                const localeSpecificCtaLink = ctaButtonSlug ? `${context.storeUrl}${ctaButtonSlug}` : undefined;
                return (
                    <BannerImage
                        layout = {layout}
                        backgroundColor = {backgroundColor}
                        desktopImage={image}
                        render={() =>
                            <BannerImageOverlay
                                html={html}
                                ctaButtonText={ctaButtonText}
                                ctaButtonLink={localeSpecificCtaLink || ctaButtonLink}
                                ctaButtonTextColor={buttonTextBlack ? '#000000' : '#FFFFFF'}
                                ctaButtonBackgroundColor={buttonBackgroundColor}
                                postButtonHtml={postButtonHtml}
                            />
                        }
                    />
                );
            }}
        </GlobalContext.Consumer>
    );
};
